import React, { useEffect, useRef } from 'react'

export default function PayPal({ article }) {

    const paypal = useRef()

    useEffect( () => {
        window.paypal.Buttons({
            createOrder: (data, actions, err) => {
                return actions.order.create({
                    intent: "CAPTURE",
                    purchase_units: [
                        {
                        description: article.articleNumber,
                            amount: {
                                currency_code: "EUR",
                                value: article.ebayPlus,
                            },
                        },
                    ],
                });
            },
            onApprove: async (data, actions) => {
                const order = await actions.order.capture();
                console.log(order);
            },
            onError: (err) => {
                console.log(err);
            }
        }).render(paypal.current)
    }, [])

  return (
    <div>
        <div ref={paypal}></div>
    </div>
  )
}
