import React, { useState } from 'react'
import "./vehicleFilter.css";
import SearchByVehicle from "./SearchByVehicle";
import SearchByHSNTSN from "./SearchByHSNTSN";

export default function VehicleFilter({ articleList, setArticleList, filteredList, setFilteredList }) {
    
  return (
    <>
        <div className="vehicleSearchWrapper">
            <SearchByVehicle articleList={articleList} setArticleList={setArticleList} filteredList={filteredList} setFilteredList={setFilteredList} />
            <SearchByHSNTSN  articleList={articleList} setArticleList={setArticleList} filteredList={filteredList} setFilteredList={setFilteredList} />
        </div>
        <span className="searchResults">{filteredList.length} Suchergebnisse</span>
    </>
  )
}
