import "./chart.css";
import { LineChart, Line, XAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import ArticleTypeChart from './ArticleTypeChart';
import ManufacturerChart from './ManufacturerChart';
import BrandChart from './BrandChart';
import ConditionChart from './ConditionChart';
import { useEffect, useState } from 'react';
import Axios from "axios";
import { databaseLocation } from '../dummyData';

export default function Charts() {

    const [articleList, setArticleList] = useState([]);

    useEffect( async () => {

        let isMounted = true;
        await Axios.get(`${databaseLocation}/api/articles/getAll`).then( (response) => {
            if(isMounted) {
                console.log("Articles loaded from database.")
                // console.log(response.data);
                setArticleList(response.data);
                // countArticleTypes(response.data);
            }
        });
        
        return () => { 
            isMounted = false;
        }

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
    {articleList.length == 0 && <h3 className="chartTitle loadingCharts">Loading Charts..</h3>}
    {articleList.length > 0 && <div>
        <ArticleTypeChart articleList={articleList} />
        <ManufacturerChart articleList={articleList} />
        <BrandChart articleList={articleList} />
        <ConditionChart articleList={articleList} />
    </div>}
    </>
  )
}
