import "./dataprotection.css";

export default function DataProtection() {

    return (
        <div className="dataProtection">
            {/* <h3>Data Protection</h3>
            <br/>
            <h4>Order Process</h4>
            <p>All data entered by the customer as part of an order processing will be saved to fulfill the order. This includes:</p>
            
            <br/>

            <p>
            - Lastname, Firstname<br/>
            - Address<br/>
            - Payment data<br/>
            - E-Mail<br/>
            </p>
              <br/>
              <br/>
  
            <p>
            The data that is absolutely necessary for delivery or order processing is passed on to third-party service providers.<br/>
            As soon as the storage of your data is no longer necessary or required by law, it will be deleted.
            </p>


            <br/>
            <br/>
            <br/>
            <hr/>
            <br/> */}
            <br/>
            <br/>


            <h3>Datenschutzerklärung</h3>
              <br />
              <h4>Bestellvorgang</h4>
              <p>Sämtliche Daten, welche im Rahmen einer Bestellabwicklung von den Kunden eingegeben werden, werden gespeichert. Dazu gehören:</p>

                <p>
                - Name, Vorname<br/>
                - Adresse<br/>
                - Zahldaten<br/>
                - E-Mail-Adresse<br/>
                </p>
  
            <p>
            Jene Daten, welche zur Auslieferung oder Auftragsabwicklung zwingend notwendig sind, werden an dritte Dienstleister weitergegeben.<br/>
            Sowie die Aufbewahrung Ihrer Daten nicht mehr erforderlich oder gesetzlich geboten ist, werden diese gelöscht.
            </p>
      </div>
    )
  }
  