import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function ProductDetails({article}) {
  return (
    <div>
         <TableContainer component={Paper} sx={{ marginTop: 10 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell sx={{ fontWeight: "800", color: "black"  }}>Power</TableCell>
                    <TableCell sx={{ fontWeight: "800", color: "black"  }}>Volt</TableCell>
                    <TableCell sx={{ fontWeight: "800", color: "black"  }}>Grooves</TableCell>
                    <TableCell sx={{ fontWeight: "800", color: "black"  }}>Diameter</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                        <TableCell sx={{ fontWeight:"600" }}>{article.power}</TableCell>
                        <TableCell sx={{ fontWeight:"600" }}>{article.volt}</TableCell>
                        <TableCell sx={{ fontWeight:"600" }}>{article.grooves}</TableCell>
                        <TableCell sx={{ fontWeight:"600" }}>{article.teeths}</TableCell>
                    </TableRow>
            </TableBody>
            </Table>
        </TableContainer>
    </div>
  )
}
