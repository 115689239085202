import React, { useEffect } from 'react'
import "./invoiceList.css";
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { databaseLocation } from "../../../dummyData";
import axios from "axios";
import { useState } from "react";
import { DeleteOutline, Search } from '@material-ui/icons';
import { Link } from "react-router-dom";

export default function InvoiceList() {

    const [InvoiceList, setInvoiceList] = useState([])
    const [ErrorMessage, setErrorMessage] = useState("")

    useEffect( async () => {

        let localInvoiceList = [];
        await axios.get(`${databaseLocation}/api/invoice/getAll`).then( (response) => {

            localInvoiceList = response.data;
        });

        //Calculate Total
        localInvoiceList.map( async invoice => {

            invoice.total = await calculateTotal(invoice)
            setInvoiceList(localInvoiceList);
        })
    }, [])

    const printWarning = () => {
        setErrorMessage("This function will be implemented soon.")
        setTimeout( () => { setErrorMessage("") }, 5000)
    }

    const handleDelete = (invoiceId) => {
        
        console.log("InvoiceID: " + invoiceId)
        axios.delete(`${databaseLocation}/api/invoice/${invoiceId}`).then( (response) => {
            setErrorMessage(response.data);
            setInvoiceList( InvoiceList.filter(invoice => invoice.invoiceId != invoiceId) );
        }).catch(err => {
            setErrorMessage(err.response.data);
        });
    }

    const calculateTotal = async (invoice) => {

        let total = 0;
        await axios.get(`${databaseLocation}/api/invoicePositions/get/${invoice.invoiceId}`).then( (response) => {
            
            const prices = response.data.map( (position) => {
                total += position.Price;
            })
            Promise.all(prices);
        });
        return Number(total);
    }

    const columns = [
        // { field: 'articleId', headerName: 'ID', width: 40 },
        { field: 'invoiceId', headerName: 'InvoiceNr.', width: 120, renderCell: (params) => {

            return (
                <>
                    <span>{"RE-"+params.row.invoiceId}</span>
                </>
            )
        }},
        { field: 'customerData', headerName: 'Customer', width: 300, renderCell: (params) => {
            return (
                <>
                    <span>{params.row.customerData.replaceAll("<br/>", " - ")}</span>
                </>
            )
        }},
        { field: 'date', headerName: 'Date', width: 100, renderCell: (params) => {

            const date = new Date(params.row.date);
            return (
                <>
                    <span>{date.toLocaleDateString("de-DE")}</span>
                </>
            )
        }},
        { field: 'employee', headerName: 'Employee', width: 160 },
        { field: 'paymentMethod', headerName: 'Payment Method', width: 140 },
        { field: 'Total', headerName: 'Total', width: 100, renderCell: (params) => {
            return (
                <>
                    <span>{params.row.total} €</span>
                </>
            )
        }},
        // { field: 'note', headerName: 'Note', width: 300 },
        { field: 'action', headerName: 'Actions', width: 300, renderCell: (params) => {
            return (
                <>
                    <button className="button action" onClick={ () => {printWarning(params.row)} }>Print Warning</button>
                    <Link to={"/invoice/"+params.row.invoiceId}> <Search className="searchIcon action" /> </Link>
                    <DeleteOutline className="deleteIcon action" onClick={ () => handleDelete(params.row.invoiceId)} />
                </>
            )
        }},
      ];

  return (
    <div className="invoiceList">
        <Link className="link createButton" to="/invoice/create">
            <button className="button createButton">Create New</button>
        </Link>
        <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={InvoiceList}
                getRowId={(row) => row.invoiceId}
                columns={columns}
                pageSize={15}
                rowsPerPageOptions={[15]}
                // checkboxSelection
                disableSelectionOnClick
                />
        </Box>
        <div>
            {ErrorMessage.length > 0 && <span className="spanError active">{ErrorMessage}</span>}
        </div>
    </div>
  )
}
