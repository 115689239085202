import Axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { databaseLocation, productData } from '../../dummyData';
import "./product.css";
import PayPal from '../../components/PayPal';
import VehicleList from './components/VehicleList';
import ProductDetails from './components/ProductDetails';
import OENumbers from './components/OENumbers';
import Button from "../../components/button/Button";

export default function Product({ user, setUser }) {

    const location = useLocation();
    const articleId = location.pathname.split("/")[2];
    // console.log("Location: " + location.pathname;

    const [article, setArticle] = useState(false);

    useEffect( () => {

        let isMounted = true;
        Axios.get(`${databaseLocation}/api/get/${articleId}`).then( (response) => {
            if(isMounted) {
                if(!response.data.includes("not logged in")) {

                    setArticle(response.data[0]);
                }
            }
        });
        
        return () => { 
            isMounted = false;
        }

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const addToCart = () => {

        if(typeof user.userId !== "undefined") {    
            Axios.post(`${databaseLocation}/addToCart`, {customerId: user.userId, articleId: article.articleId}).then( (response) => {
                console.log(response);
                user.cartAmount = 1;
                setUser(user)
            });
        }
    }

  return (
      <div className="product">
        {article && <div className="productTop">
            <div className="productTopLeft">
                <h2>{article.articleNumber}</h2>
                <img src={article.URL} alt={article.articleType} className="articleImg" />
            </div>
            <div className='productTopCenter'>
                <table>
                    <thead className='tableRow'>
                        <tr>
                            <th>Artikel</th>
                            <td>{article.articleType}</td>
                        </tr>
                        <tr>
                            {/* <th>Condition</th> */}
                            <th>Zustand</th>
                            <td>{article.state}</td>
                        </tr>
                        <tr>
                            {/* <th>Manufacturer</th> */}
                            <th>Hersteller</th>
                            <td>{article.manufacturer}</td>
                        </tr>
                        <tr>
                            {/* <th>Price</th> */}
                            <th>Preis</th>
                            <td>{article.ebayPlus}€ inkl. MwSt</td>
                        </tr>
                    </thead>
                </table>
                <div className="stock">
                    {/* {article.quantity > 0 ? <Button type="In Stock"/> : <Button type="Out of Stock"/>} */}
                    {article.quantity > 0 ? <Button type="In" text="Verfügbar" /> : <Button type="Ausverkauft"/>}
                </div>
            </div>
            <div className="productTopRight">
                <PayPal article={article} />
                <div className="">
                    <ul className="shippingTime">
                        {/* <li>Fast Delivery</li> */}
                        {/* <li>1-2 days shipping</li> */}
                        <li>Schnelle Lieferung</li>
                        <li>1-2 Werktage</li>
                    </ul>
                    <h3 className="totalPrice">{article.ebayPlus}€ inkl. MwSt</h3>
                    <Link to={"/cart"}>
                        <button className="buyButton" onClick={addToCart}>Bestellen</button>
                    </Link>
                </div>
            </div>
      </div>}
      {article && <div className="productBody">
        {article.articleType === "Alternator" &&
            <div className="pawnAdvice">
                <span>
                    Wir benötigen Ihre alte Lichtmaschine innerhalb von 14 Tagen zurück, andernfalls kann Ihnen nach Ablauf der 14 Tage 50 EUR Pfand berechnet werden!<br/>
                    In der Regel liegt ein Paketschein für den Rückversand an uns mit bei, dieser ist allerdings nur für Käufer innerhalb Deutschlands nutzbar.
                </span>
                {/* <span>
                    We need your old alternator within 14 days back, otherwise you can be charged with 50 EUR!<br/>
                    Usally there is a shipping label in the package for the return to us, but this is only for buyers from Germany.
                </span> */}
            </div>
        }
        <ProductDetails article={article} />
        <VehicleList article={article} />
        <OENumbers  article={article} />
        </div>}
    </div>
  )
}
