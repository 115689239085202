import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { databaseLocation } from '../../../dummyData';
import Axios from "axios";

export default function VehicleList({article}) {

    const [vehicleList, setVehicleList] = useState([]);

    useEffect( () => {

        let isMounted = true;
        Axios.get(`${databaseLocation}/api/vehicleList/${article.articleId}/withKType`).then( (response) => {
            if(isMounted) {
                setVehicleList(response.data);
                console.log(response.data);
            }
        });
        
        return () => { 
            isMounted = false;
        }

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
        {vehicleList.length > 0 && <TableContainer component={Paper} sx={{ marginTop: 10 }}>
            {/* <span>Vehicles: {vehicleList.length}</span> */}
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow key="headers">
                        {/* <TableCell key="brand" sx={{ fontWeight: "800", color: "black"  }}>Brand</TableCell>
                        <TableCell key="model" sx={{ fontWeight: "800", color: "black"  }}>Model</TableCell>
                        <TableCell key="year" sx={{ fontWeight: "800", color: "black"  }}>Year</TableCell>
                        <TableCell key="platform" sx={{ fontWeight: "800", color: "black"  }}>Platform</TableCell>
                        <TableCell key="type" sx={{ fontWeight: "800", color: "black"  }}>Type</TableCell>
                        <TableCell key="engine" sx={{ fontWeight: "800", color: "black"  }}>Engine</TableCell>
                        <TableCell key="hsnTsn" sx={{ fontWeight: "800", color: "black"  }}>HSN/TSN</TableCell> */}

                        <TableCell key="brand" sx={{ fontWeight: "800", color: "black"  }}>Marke</TableCell>
                        <TableCell key="model" sx={{ fontWeight: "800", color: "black"  }}>Modell</TableCell>
                        <TableCell key="year" sx={{ fontWeight: "800", color: "black"  }}>Jahr</TableCell>
                        <TableCell key="platform" sx={{ fontWeight: "800", color: "black"  }}>Platform</TableCell>
                        <TableCell key="type" sx={{ fontWeight: "800", color: "black"  }}>Typ</TableCell>
                        <TableCell key="engine" sx={{ fontWeight: "800", color: "black"  }}>Motor</TableCell>
                        <TableCell key="hsnTsn" sx={{ fontWeight: "800", color: "black"  }}>HSN/TSN</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {vehicleList.map( (vehicle, index) => {
                        return (
                            <TableRow key={index}>
                                    <TableCell sx={{ fontWeight:"600" }} key={vehicle.brand+"_"+index}>{vehicle.brand}</TableCell>
                                    <TableCell sx={{ fontWeight:"600" }} key={vehicle.model+"_"+index}>{vehicle.model}</TableCell>
                                    <TableCell sx={{ fontWeight:"600" }} key={vehicle.year+"_"+index}>{vehicle.year}</TableCell>
                                    <TableCell sx={{ fontWeight:"600" }} key={vehicle.platform+"_"+index}>{vehicle.platform}</TableCell>
                                    <TableCell sx={{ fontWeight:"600" }} key={vehicle.vType+"_"+index}>{vehicle.vType}</TableCell>
                                    <TableCell sx={{ fontWeight:"600" }} key={vehicle.engine+"_"+index}>{vehicle.engine}</TableCell>
                                    <TableCell sx={{ fontWeight:"600" }} key={vehicle.hsnTsn+"_"+index}>{vehicle.hsnTsn.replaceAll("<>", " ")}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>}
    </div>
  )
}
