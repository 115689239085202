import Topbar from "./components/topbar/Topbar";
import Sidebar from "./components/sidebar/Sidebar";
import Home from "./pages/home/Home";
import Shop from "./pages/shop/Shop";
import "./app.css";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import UserList from './pages/userList/UserList';
import User from './pages/user/User';
import NewUser from './pages/newUser/NewUser';
import ProductList from './pages/productList/ProductList';
import Product from './pages/product/Product';
import NewProduct from './pages/newProduct/NewProduct';
import Login from './pages/login/Login';
import Profile from './pages/profile/profile';
import { databaseLocation } from './dummyData';
import Footer from './components/footer/Footer';
import NotLoggedIn from './pages/notLoggedIn/NotLoggedIn';
import Imprint from './pages/Information/imprint/Imprint';
import DataProtection from './pages/Information/dataprotection/DataProtection';
import Interesting from './pages/Information/interesting/Interesting';
import Contact from './pages/Information/contact/Contact';
import CreateInvoice from "./pages/invoice/CreateInvoice/CreateInvoice";
import EditInvoice from "./pages/invoice/EditInvoice/EditInvoice";
import InvoiceList from "./pages/invoice/InvoiceList/InvoiceList";
import LevelToLow from "./pages/notLoggedIn/NotLoggedIn";
import Orders from "./pages/orders/Orders";
import Cart from "./pages/cart/Cart";
import LoginRegister from "./popups/loginRegister/loginRegister";
import UserCreation from "./pages/register/userCreation/userCreation";
import Register from "./pages/register/Register";

function App() {

    const [loggedIn, setLoggedIn] = useState(false);
    const [user, setUser] = useState({});
    const [ClientAccessToken, setClientAccessToken] = useState({});
    const [language, setLanguage] = useState("german");

  return (
    <Router>
      <Topbar loggedIn={loggedIn} user={user} />
      <div className="mainContainer">
        <Sidebar loggedIn={loggedIn} />
        <Routes>
            {/* {console.log(window.location)} */}
            <Route path="/" element={ <Shop /> } />
            <Route path="/login" element={ <Login loggedIn={loggedIn} setLoggedIn={setLoggedIn} setUser={setUser} desiredPath={window.location.pathname} databaseLocation={databaseLocation} /> } />
            <Route path="/loginRegister" element={ <LoginRegister /> } />
            <Route path="/home" element={ loggedIn ? <Home/> : <NotLoggedIn /> } />
            <Route path="/shop" element={ <Shop /> } />
            <Route path="/shop/:articleId" element={ <Product user={user} setUser={setUser} /> } />
            <Route path="/users" element={ loggedIn ? <UserList/> : <NotLoggedIn /> } />
            <Route path="/users/:userId" element={ loggedIn ? <User /> : <NotLoggedIn /> } />
            <Route path="/newUser" element={ loggedIn ? user.level >= 5 ? <NewUser /> : <LevelToLow /> : <NotLoggedIn /> } />
            <Route path="/products" element={ loggedIn ? <ProductList /> : <Login loggedIn={loggedIn} setLoggedIn={setLoggedIn} setUser={setUser} desiredPath={window.location.pathname} databaseLocation={databaseLocation} /> } />
            <Route path="/products/:searchText" element={ loggedIn ? <ProductList /> : <Login loggedIn={loggedIn} setLoggedIn={setLoggedIn} setUser={setUser} desiredPath={window.location.pathname} databaseLocation={databaseLocation} /> } />
            {/* <Route path="/invoice" element={ loggedIn ? <Invoice /> : <NotLoggedIn /> } /> */}
            <Route path="/invoice" element={ loggedIn ? <InvoiceList /> : <Login loggedIn={loggedIn} setLoggedIn={setLoggedIn} setUser={setUser} desiredPath={window.location.pathname} databaseLocation={databaseLocation} /> } />
            <Route path="/invoice/create" element={ loggedIn ? <CreateInvoice /> : <Login loggedIn={loggedIn} setLoggedIn={setLoggedIn} setUser={setUser} desiredPath={window.location.pathname} databaseLocation={databaseLocation} /> } />
            <Route path="/invoice/:invoiceId" element={ loggedIn ? <EditInvoice /> : <Login loggedIn={loggedIn} setLoggedIn={setLoggedIn} setUser={setUser} desiredPath={window.location.pathname} databaseLocation={databaseLocation} /> } />
            <Route path="/products/:productId" element={ loggedIn ? <Product /> : <NotLoggedIn /> } />
            <Route path="/newProduct" element={ loggedIn ? <NewProduct /> : <NotLoggedIn /> } />
            <Route path="/profile" element={ loggedIn ? <Profile user={user} setUser={setUser} /> : <NotLoggedIn /> } />
            <Route path="/imprint" element={ <Imprint /> } />
            <Route path="/dataprotection" element={ <DataProtection /> } />
            <Route path="/interesting" element={ <Interesting /> } />
            <Route path="/contact" element={ <Contact /> } />
            <Route path="/orders" element={ <Orders /> } />
            <Route path="/cart" element={ <Cart loggedIn={loggedIn} user={user} /> } />
            <Route path="/register" element={ <Register setLoggedIn={setLoggedIn} setUser={setUser} desiredPath="/" /> } />
        </Routes>
      </div>
    <Footer />
    </Router>
  );
}

export default App;