import "../register.css"

const UserCreation = ({ setUsername, setPassword, setMail, verify1 }) => {

  return (
    <div className="userCreationWrapper">
        <div className="header">
            <h1>Registrierung</h1>
            <span className="subheader">Dies werden deine Einlogdaten für unseren Webshop.</span>
        </div>
        <div className="registerItems">
            {/* <div className="registerUserItem">
                <label>Benutzername</label>
                <input type="username" onChange={ (e) => { setUsername(e.target.value); }} />
            </div> */}
            <div className="registerUserItem">
                <label>Email</label>
                <input type="email" onChange={ (e) => { setMail(e.target.value); }} />
            </div>
            <div className="registerUserItem">
                <label>Passwort</label>
                <input type="password" onChange={ (e) => { setPassword(e.target.value); }} />
            </div>
            <div className="btnWrapper">
                <button className="button registerButton" onClick={verify1}>Weiter</button>
            </div>
        </div>
    </div>
  )
}

export default UserCreation