import React from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

export default function OENumbers({article}) {
  return (
    <div>
        <List
            sx={{
            marginTop: 10,
            width: '100%',
            textAlign: "center",
            bgcolor: 'background.paper',
            position: 'relative',
            overflow: 'auto',
            maxHeight: 300,
            '& ul': { padding: 0 },
        }}
            subheader={<li />}
        >
            {/* <ListSubheader sx={{ fontWeight: "800", color: "black" }}>{`Comparsion Numbers`}</ListSubheader> */}
            <ListSubheader sx={{ fontWeight: "800", color: "black" }}>{`Vergleichsnummern`}</ListSubheader>
            {article.oe.split(",").map((oe, index) => (
                <li key={`${oe}_${index}`}>
                <ul>
                    <ListItem key={`${oe}_${index}`} >
                        <ListItemText sx={{ textAlign: "center" }} primary={oe} />
                    </ListItem>
                </ul>
                </li>
            ))}
        </List>
    </div>
  )
}
