import React, { useEffect, useState } from 'react';
import "./topbar.css";
import { NotificationsNone, Language, Settings} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import logo from '../../images/logo.png';
import { databaseLocation } from "../../dummyData";
import axios from "axios";

const Topbar = ({ loggedIn, user }) => {

    const [inCartAmount, setInCartAmount] = useState(0);

    useEffect(() => {

        let isMounted = true;
        if(loggedIn) {
            axios.get(`${databaseLocation}/getInCartAmount/${user.userId}`).then( (response) => {
                if(isMounted) {
                    console.log("Topnar")
                    console.log(response.data)
                    setInCartAmount(response.data[0].amount)
                }
            });
        }

        return () => { 
            isMounted = false;
        }
    }, [loggedIn])
    
    
    return (
        <div className="topbar">
            <div className="topbarWrapper">
                <div className="topLeft">
                    <Link to="/shop">
                        {/* <img className='logoImg' alt="logo" src='https://cdn.shopify.com/s/files/1/0540/2355/3219/files/Logo-PNG.png?v=1654506599'></img> */}
                        <img className='logoImg' alt="logo" src={logo} ></img>
                    </Link>
                    {/* <SidebarMenu loggedIn={loggedIn} /> */}
                </div>
                <div className="topRight">
                    {/* <div className="topbarIconContainer">
                        <NotificationsNone/>
                        <span className="topIconBadge">2</span>
                    </div> */}
                    {/* <div className="topbarIconContainer">
                        <Language onClick={this.changeColor}/>
                    </div> */}
                    {/* <div className="topbarIconContainer">
                        <Settings/>
                    </div> */}
                    { loggedIn === true ?
                    <div className="loggedInWrapper">
                        <Link to="/cart">
                            <ShoppingCartIcon className="shoppingCartIcon" />
                            <span className={inCartAmount > 0 ? `topIconBadge` : "hiddenIconBadge"}>{inCartAmount}</span>
                        </Link>
                        <Link to="/profile">
                            <img src={user.avatar} alt="Avatar" className="topAvatar" />
                        </Link>
                    </div>
                    :
                    <div className="topbarIconContainer">
                        <Link to="/login">
                            <AdminPanelSettingsIcon className="loginIcon" />
                        </Link>
                    </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Topbar;