import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField';
import { FormControl } from "@mui/material";
import axios from "axios";
import { databaseLocation } from "../../../dummyData";

const SearchByHSNTSN = ({ articleList, setArticleList, filteredList, setFilteredList }) => {

    const [hsn, setHSN] = useState("");
    const [tsn, setTSN] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect( async () => {

        if(hsn.length < 4 || tsn.length < 3) { return; }

        setLoading(true);

        let isMounted = true;

        let alreadySavedArticleIds = [];
        await axios.get(`${databaseLocation}/getArticleIdsWithHSNTSN/${hsn}/${tsn}`).then( (response) => {
            if(isMounted) {
                console.log("Articles loaded from database.")
                console.log(response.data);

                var newArticleList = [];
                const promises1 = response.data.map( (article) => {
                    const promises2 = article.map( (entry) => {

                        if(!alreadySavedArticleIds.includes(entry.articleId)) {
                            
                            alreadySavedArticleIds.push(entry.articleId)
    
                            newArticleList.push({
                                articleId: entry.articleId,
                                brand: entry.brand,
                                power: entry.power,
                                articleType: entry.articleType,
                                storagePlace: entry.storagePlace,
                                articleNumber: entry.articleNumber,
                                quantity: entry.quantity,
                                manufacturer: entry.manufacturer,
                                state: entry.state,
                                oe: entry.oe,
                                ebayPlus: entry.ebayPlus,
                                URL: entry.URL,
                                proofed: entry.proofed,
                                vehicleListAvailable: entry.vehicleListAvailable,
                                webshop: entry.webshop,
                                vehicles: [{
                                    brand: entry.Brand,
                                    engine: entry.Engine,
                                    model: entry.Model,
                                    year: entry.Year,
                                    platform: entry.Platform,
                                    vType: entry.vType,
                                    note: entry.Note,
                                    hsnTsn: entry.hsnTsn
                                }]
                            });
                        }
                        else {
                            const foundArticle = newArticleList.find( (x) => {
                                return x.articleId == entry.articleId;
                            });
                            foundArticle.vehicles.push({
                                brand: entry.Brand,
                                engine: entry.Engine,
                                model: entry.Model,
                                year: entry.Year,
                                platform: entry.Platform,
                                vType: entry.vType,
                                note: entry.Note,
                                hsnTsn: entry.hsnTsn
                            })
                        }
                    })
                    Promise.all(promises2);
                })
                Promise.all(promises1);

                setLoading(false);
                setFilteredList(newArticleList.filter( article => article.URL.length > 3 && !article.URL.includes("robarcko") && !article.articleType.includes("Regulator")))
            }
            console.log("NewArticleList");
            console.log(newArticleList);
        })
        .catch( (err) => {
            console.log(err)
        });

        return () => { 
            isMounted = false;
        }

    }, [hsn, tsn]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className="vehicleFilter">
        <FormControl fullWidth sx={{marginTop: 2}}>
            <span className="title">HSN/TSN (Schlüsselnummer) Suche</span>
            <div className="hsnTsnSearch">
                <TextField className="hsnInput" label="HSN" variant="outlined" onChange={ (e) => { setHSN(e.target.value); }} />
                <TextField className="tsnInput" label="TSN" variant="outlined" onChange={ (e) => { setTSN(e.target.value); }} />
            </div>
        </FormControl>
        {loading &&                 
                    <div className="littleResultsMSG">
                        <h3>Artikel laden..</h3>
                    </div>
        }
    </div>
  )
}

export default SearchByHSNTSN
