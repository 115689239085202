import React, { useState } from 'react'
import { DeleteOutline } from '@material-ui/icons';
import "./CreateInvoice/createInvoice.css"

export default function InvoicePosition({ pos, position, setSpecificInvoicePosition, deleteInvoicePosition}) {

    const updateArticleNumber = (event) => {

        position.ArticleNumber = event.target.value;
        setSpecificInvoicePosition(position)
    }

    const updateDescription = (event) => {

        position.Description = event.target.value;
        setSpecificInvoicePosition(position)
    }

    const updateQuantity = (event) => {

        position.Quantity = event.target.value;
        setSpecificInvoicePosition(position)
    }

    const updatePrice = (event) => {

        position.Price = event.target.value;
        setSpecificInvoicePosition(position)
    }

    const updateCurrency = (event) => {

        position.Currency = event.target.value;
        setSpecificInvoicePosition(position)
    }

    const updateTax = (event) => {

        position.Tax = event.target.value;
        setSpecificInvoicePosition(position)
    }

    const handleDelete = (event) => {
        deleteInvoicePosition(position)
    }

  return (
    <tr>
        <td>
            <input type="text" placeholder="Pos" value={(position.Pos+1)} disabled/>
        </td>
        {/* <td>
            <input type="text" placeholder="Artikelnr." value={position.ArticleNumber} onChange={updateArticleNumber} />
        </td> */}
        <td>
            <input type="text" placeholder="Kurzbeschreibung" value={position.Description} onChange={updateDescription} />
        </td>
        <td>
            <input type="text" placeholder="Menge" value={position.Quantity} onChange={updateQuantity} />
        </td>
        <td>
            <input id="price" type="text" placeholder="Preis" value={position.Price} onChange={updatePrice} />
        </td>
        {/* <td>
            <input type="text" placeholder="Einheit" value={position.Currency} onChange={updateCurrency} />
        </td> */}
        <td>
            <input type="text" placeholder="UST" value={position.Tax} onChange={updateTax} />
        </td>
        <td>
            <DeleteOutline className="deleteIcon" onClick={handleDelete} />
        </td>
    </tr>
  )
}
