import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { DeleteOutline, Search } from '@material-ui/icons';
import { databaseLocation } from '../../dummyData';
import { Link, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Axios from "axios";
import "./productList.css";
import { TextField } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';

export default function ProductList() {

    const [articleList, setArticleList] = useState([]);
    const [filteredList, setFilteredList] = useState([]);

    const [searchText, setSearchText] = useState("");
    const [filter, setFilter] = useState([]);

    const location = useLocation();
    console.log(location);
    
    
    useEffect( async () => {
        
        let isMounted = true;
        await Axios.get(`${databaseLocation}/api/articles/getAll`).then( (response) => {
            if(isMounted) {
                console.log("Articles loaded from database.")
                setArticleList(response.data);
                // setSearchText("");
                setFilteredList(response.data);
                console.log(response.data);

                if(location.pathname.split("/").length > 2) {
                    setSearchText(location.pathname.split("/")[2]);
                }
            }
        }).catch( (err) => {
            console.log(err);
        });
        
        return () => { 
            isMounted = false;
        }

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleDelete = (id) => {
        setArticleList(articleList.filter(article => article.articleId !== id));
    }

    const handleFilter = (value) => {
        console.log("Value: " + value);
        if(value.length < 1) {
            setFilter([]);
            setFilteredList(articleList);
            console.log("Clearing Filter");
        }
        else {
            setFilter(value);
            let tempList = articleList;
            if(value.includes("NotOnEbay")) {
                tempList = tempList.filter(article => article.onEbay == 0);
            }
            if(value.includes("WithPicture")) {
                tempList = tempList.filter(article => article.URL.length > 3);
            }
            if(value.includes("WithoutPicture")) {
                tempList = tempList.filter(article => article.URL.length < 3);
            }
            if(value.includes("NoRegulator")) {
                tempList = tempList.filter(article => !article.articleType.toLowerCase().includes("regulator"));
            }
            if(value.includes("InStock")) {
                tempList = tempList.filter(article => article.quantity > 0);
            }
            setFilteredList(tempList);
        }
    }

    const incrementHandler = async (article) => {

        await Axios.put(`${databaseLocation}/api/updateQuantity`, {
              quantity: (article.quantity+1),
              articleId: article.articleId,
              clientAccessToken: "lima454#"
        }).then( (res) => {
            article.quantity += 1;
            updateArticleInList(article);
        }).catch( (e) => {

        });
  };

  const decrementHandler = (article) => {
      
        if(article.quantity == 0) { return; }
        Axios.put(`${databaseLocation}/api/updateQuantity`, {
            quantity: (article.quantity-1),
            articleId: article.articleId,
            clientAccessToken: "lima454#"
        }).then( (res) => {
            article.quantity -= 1;
            updateArticleInList(article);
        }).catch( (e) => {

        });
  };
      
  const updateArticleInList = (article) => {

    const newList = articleList.map((item) => {
        if (item.articleId === article.articleId) {
          const updatedItem = {
            ...item,
            quantity: article.quantity,
          };
          return updatedItem;
        }
        return item;
      });
      setArticleList(newList);
}

    /** Search Handler **/
    useEffect( () => {
        console.log("SearchText: " + searchText);
        const searchTxt = searchText.toLowerCase().replaceAll(" ", "");
        setFilteredList(articleList.filter(article => article.articleNumber.replaceAll(" ", "").toLowerCase().includes(searchTxt) || article.oe.replaceAll(" ", "").toLowerCase().includes(searchTxt)));
    }, [searchText]); // eslint-disable-line react-hooks/exhaustive-deps

    const columns = [
        // { field: 'articleId', headerName: 'ID', width: 40 },
        { field: 'articleType', headerName: 'Type', width: 110 },
        { field: 'brand', headerName: 'Brand', width: 120 },
        { field: 'power', headerName: 'Power', width: 70 },
        { field: 'storagePlace', headerName: 'Place', width: 100 },
        { field: 'quantity', headerName: 'Qty', width: 50 },
        { field: 'articleNumber', headerName: 'Article', width: 100 },
        { field: 'manufacturer', headerName: 'Manufacturer', width: 80 },
        { field: 'ebayPlus', headerName: 'Price', numeric: true, width: 80 },
        { field: 'onEbay', headerName: 'onEbay', numeric: true, width: 80 },
        { field: 'URL', headerName: '', width: 80, renderCell: (params) => {
            return (
                    <div className="productListItem">
                        {params.row.URL.length > 3 && <img className="productListImg" src={params.row.URL} alt="" /> }
                    </div>
                ) },
        },
        { field: 'action', headerName: 'Actions', width: 150, renderCell: (params) => {
            return (
                <>
                    {/* <Link to={"/products/"+params.row.id}>
                        <button className="productListEdit">Edit</button>
                    </Link> */}
                    <button className="btn productListIncrement" onClick={ () => {incrementHandler(params.row)} }>+</button>
                    <button className="btn productListDecrement" onClick={ () => {decrementHandler(params.row)} }>-</button>
                    <Link to={"/shop/"+params.row.articleId}>
                        <Search />
                    </Link>
                    {/* <DeleteOutline className="productListDelete" onClick={ () => handleDelete(params.row.articleId)} /> */}
                </>
            )
        } },
      ];

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            },
        },
    };

    const names = [
        'NotOnEbay',
        'WithPicture',
        'WithoutPicture',
        'NoRegulator',
        'InStock',
    ];

    const theme = useTheme();
    const [personName, setPersonName] = useState([]);

    function getStyles(name, personName, theme) {
        return {
          fontWeight:
            personName.indexOf(name) === -1
              ? theme.typography.fontWeightRegular
              : theme.typography.fontWeightMedium,
        };
    }

  return (
    <div className="productList">
        <div className="searchInput">
            <TextField placeholder='Search..' value={searchText} onChange={ (e) => { setSearchText(e.target.value); }} />
            <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-multiple-chip-label">Filter</InputLabel>
                <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={filter}
                    onChange={ (e) => { handleFilter(e.target.value); }}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                            <Chip key={value} label={value} />
                        ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {names.map((name) => (
                        <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, personName, theme)}
                        >
                        {name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>

        <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={filteredList}
                getRowId={(row) => row.articleId}
                columns={columns}
                pageSize={15}
                rowsPerPageOptions={[15]}
                // checkboxSelection
                disableSelectionOnClick
                />
        </Box>
    </div>
  )
}
