import axios from "axios";
import React, { useEffect, useState } from 'react'
import { databaseLocation } from "../../dummyData";
import LoginRegister from "../../popups/loginRegister/loginRegister";
import "./cart.css"
import ShopProductsListing from "../../components/shopProductsList/shopProductsListing";
import { Add, DeleteOutline, Remove } from "@material-ui/icons";

const Checkout = ({ loggedIn, user }) => {

    const [articleList, setArticleList] = useState([])

    useEffect( () => {

        let isMounted = true;
        axios.get(`${databaseLocation}/getCartArticles/${user.userId}`).then( (response) => {
            if(isMounted) {
                setArticleList(response.data)
            }
        });
        
        return () => { 
            isMounted = false;
        }

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const incrementHandler = async (article) => {

        await axios.put(`${databaseLocation}/cart/updateQuantity`, {
            customerId: user.userId,
            articleId: article.articleId,
            amount: (article.amount+1)
        }).then( (res) => {
            article.amount += 1;
            updateArticleInList(article);
        }).catch( (e) => {
            console.log(e);
        });
  };

  const decrementHandler = (article) => {
      
    if(article.amount == 0) { return; }
    if(article.amount == 1) { return deleteHandler(article) }
    axios.put(`${databaseLocation}/cart/updateQuantity`, {
        customerId: user.userId,
        articleId: article.articleId,
        amount: (article.amount-1)
    }).then( (res) => {
        article.amount -= 1;
        updateArticleInList(article);
    }).catch( (e) => {
        console.log(e);
    });
};

    const deleteHandler = (article) => {
        
        axios.delete(`${databaseLocation}/cart/remove/${user.userId}/${article.articleId}`).then( (res) => {
            setArticleList(articleList.filter(entity => article.articleId !== entity.articleId));
        }).catch( (e) => {
            console.log(e);
        });
    };

    const updateArticleInList = (article) => {

        const newList = articleList.map((item) => {
            if (item.articleId === article.articleId) {
              const updatedItem = {
                ...item,
                amount: article.amount,
              };
              return updatedItem;
            }
            return item;
          });
          setArticleList(newList);
    }

  return (
    <div className="topCartWrapper">
        {!loggedIn ? <LoginRegister /> : 
        <div className="cartWrapper">
            {/* <h1>Shopping Cart</h1> */}
            <h1>Warenkorb</h1>
            {articleList.length > 0 ? articleList.map( (article, index) => (

                <div key={index}>
                    <div className="cartListingMenu">
                        <span className="inCartAmountLabel">{article.amount}</span>
                        <Add className="icon add" onClick={ () => {incrementHandler(article)} } />
                        <Remove className="icon remove" onClick={ () => {decrementHandler(article)} } />
                        <DeleteOutline className="icon trash" onClick={ () => {deleteHandler(article)} } />
                    </div>
                    <ShopProductsListing article={article} />
                    <div className="checkoutButtonWrapper">
                        <button className="checkoutButton">Zur Kasse</button>
                    </div>
                </div>
            // )) : <span>You have nothing in your cart yet.</span>}
            )) : <span>Du hast noch nichts in deinem Warenkorb.</span>}
        </div>}
    </div>
  )
}

export default Checkout;