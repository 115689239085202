import "./featuredInfo.css";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import { useEffect } from "react";
import { databaseLocation } from "../../dummyData";
import { useState } from "react";
import axios from "axios";

export default function FeaturedInfo() {

    const [InvoiceList, setInvoiceList] = useState([])
    const [TotalRevenue, setTotalRevenue] = useState([])
    const [MonthlyRevenue, setMonthlyRevenue] = useState(0)

    useEffect( async () => {

        await axios.get(`${databaseLocation}/api/invoice/getAll`).then( (response) => {
            console.log(response.data)
            setInvoiceList(response.data);
        });
    }, [])
    
    useEffect( () => {
        if(InvoiceList.length > 0) {
            calculateTotalRevenue();
            calculateMonthlyRevenue();
        }
    }, [InvoiceList])
    
    const calculateTotalRevenue = () => {

        let revenue = 0.0;
        InvoiceList.map( async (invoice, index) => {

            //Get InvoicePositions
            await axios.get(`${databaseLocation}/api/invoicePositions/get/${invoice.invoiceId}`).then( (response) => {
            
                const prices = response.data.map( (position) => {
                    revenue += position.Price;
                })
                Promise.all(prices);
            });
            setTotalRevenue(revenue);
        });
    }

    const calculateMonthlyRevenue = () => {

        let revenue = 0.0;
        InvoiceList.map( async (invoice) => {

            let invoiceDate = new Date(invoice.date);
            let todaysDateMinusThirty = new Date();
            todaysDateMinusThirty.setDate(todaysDateMinusThirty.getDate() - 30)
            // console.log("InvoiceDate: " + invoiceDate.getMonth());
            // console.log("todaysDateMinusThirty: " + todaysDateMinusThirty.getMonth());
            if(invoiceDate >= todaysDateMinusThirty) {

                //Get InvoicePositions
                await axios.get(`${databaseLocation}/api/invoicePositions/get/${invoice.invoiceId}`).then( (response) => {
                
                    const prices = response.data.map( (position) => {
                        revenue += position.Price;
                    })
                    Promise.all(prices);
                });
                setMonthlyRevenue(revenue);
            }
        });
    }

  return (
      <div className="featured">
        <div className="featuredItem">
            <span className="featuredTitle">Revenue</span>
            <div className="featuredMoneyContainer">
                <span className="featuredMoney">{MonthlyRevenue} €</span>
                {/* <span className="featuredMoneyRate">
                    $-11.4 <ArrowDownward className="featuredIcon negative"/>
                </span> */}
            </div>
            <span className="featuredSub">Last 30 days</span>
        </div>
        <div className="featuredItem">
            <span className="featuredTitle">Revenue</span>
            <div className="featuredMoneyContainer">
                <span className="featuredMoney">{TotalRevenue} €</span>
                {/* <span className="featuredMoneyRate">
                    $-11.4 <ArrowDownward className="featuredIcon negative"/>
                </span> */}
            </div>
            <span className="featuredSub">In Total</span>
        </div>
        {/* <div className="featuredItem">
            <span className="featuredTitle">Sales</span>
            <div className="featuredMoneyContainer">
                <span className="featuredMoney">$2.415</span>
                <span className="featuredMoneyRate">
                    $-11.4 <ArrowDownward className="featuredIcon negative"/>
                </span>
            </div>
            <span className="featuredSub">Compared to last month</span>
        </div> */}
        {/* <div className="featuredItem">
            <span className="featuredTitle">Cost</span>
            <div className="featuredMoneyContainer">
                <span className="featuredMoney">$2.415</span>
                <span className="featuredMoneyRate">
                    $+2.4 <ArrowUpward className="featuredIcon"/>
                </span>
            </div>
            <span className="featuredSub">Compared to last month</span>
        </div> */}
      </div>
  )
}
