import Charts from '../../chart/Chart'
import ManufacturerChart from '../../chart/ManufacturerChart'
import FeaturedInfo from '../../components/featuredInfo/FeaturedInfo'
import "./home.css"
import { userData } from "../../dummyData";
import WidgetSm from '../../components/widgetSm/WidgetSm';
import WidgetLg from '../../components/widgetLg/WidgetLg';
import ArticleTypeChart from '../../chart/ArticleTypeChart';
import BrandChart from '../../chart/BrandChart';
import ConditionChart from '../../chart/ConditionChart';

export default function Home() {
  return (
    <div className="home">
        <FeaturedInfo/>
        <Charts />
        {/* <div className="homeWidget">
          <WidgetSm />
          <WidgetLg />
        </div> */}
    </div>
  )
}
