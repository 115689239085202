import React, { useEffect, useState } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { data, databaseLocation } from "../dummyData";
import "./chart.css";
import Axios from "axios";

export default function ArticleTypeChart({ articleList }) {

    const [articleTypeCounter, setArticleTypeCounter] = useState([]);

    const barColors = ["#1f77b4", "#ff7f0e", "#2ca02c", "#962c28", "#a02c79"]

    useEffect( async () => {
        
        //initiliaze
        let counter = [{ }];
        articleList.map( (article) => {
            counter[0][article.articleType] = 0;
        })
        
        //count
        const promises = articleList.map( (article) => {
            
            counter[0][article.articleType] += article.quantity;
        })
        const results = Promise.all(promises);
        // console.log(counter)

        //take only bigger manufacturers
        let counter2 = [{ Others: 0 }];
        Object.keys(counter[0]).map( (type) => {

            if(counter[0][type] > 0) {
                counter2[0][type] = counter[0][type];
            }
            else {
                counter2[0]["Others"] += counter[0][type];
            }
        })

        //delete "Others" if is null
        if(counter2[0]["Others"] == 0) {
            delete counter2[0].Others;
        }
        // console.log(counter2);
        setArticleTypeCounter(counter2);

    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    
    return (
        <>
            {articleTypeCounter.length > 0 && 
                <div className="chart">
                    <h3 className="chartTitle">Article Types Chart</h3>
                    {/* <ResponsiveContainer className="responsiveContainer" aspect={4 / 1}> */}
                    <ResponsiveContainer  width="100%" height="100%">
                        <BarChart data={articleTypeCounter} margin={{ top: 20 }}>
                            {Object.keys(articleTypeCounter[0]).map( (manu, index) => {
                                return (
                                    <Bar key={manu} dataKey={manu} fill={barColors[index % 5]} label={{ position: 'top' }} />
                                )
                            })}
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip cursor={{fill: 'transparent'}} />
                            <Legend />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            }
        </>
  )
}
