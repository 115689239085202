import "./newUser.css";
import { useState } from 'react';
import { databaseLocation } from '../../dummyData';
import Axios from 'axios';
import { Link } from "react-router-dom";
//Radio Button
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function NewUser() {

    const [success, setSuccess] = useState(false);
    const [error, setError] = useState("");

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [level, setLevel] = useState("");
    const [gender, setGender] = useState("");

    const handleUserCreation = () => {

        if(username.length < 3) {
            setError("Please provide a Username.");
            return;
        }

        if(firstname.length < 3 || lastname.length < 3) {
            setError("Please provide a First- and Lastname.");
            return;
        }

        Axios.post(`${databaseLocation}/api/user/create`, {
            username: username,
            password: password,
            firstname: firstname,
            lastname: lastname,
            level: level,
            gender: gender
        })
        .then( (res) => {
            console.log(res);
        })
        .catch( (err) => {
            console.log(err);
        })

        setSuccess(true);
        setError("");
    }

  return (
    <div className="newUser">
        <h1 className="newUserTitle">New User</h1>
        <form className="newUserForm">
            <div className="newUserItem">
                <label>Username</label>
                <input type="text" placeholder="jony" onChange={ (e) => { setUsername(e.target.value) }} />
            </div>
            <div className="newUserItem">
                <label>Password</label>
                <input type="password" placeholder="password" onChange={ (e) => { setPassword(e.target.value) }} />
            </div>
            <div className="newUserItem">
                <label>Firstname</label>
                <input type="text" placeholder="Jon" onChange={ (e) => { setFirstname(e.target.value) }} />
            </div>
            <div className="newUserItem">
                <label>Lastname</label>
                <input type="text" placeholder="Snow" onChange={ (e) => { setLastname(e.target.value) }} />
            </div>
            <div className="newUserItem">
                <label>Level</label>
                <input type="text" placeholder="1" onChange={ (e) => { setLevel(e.target.value) }} />
            </div>
            <div className="newUserItem">
                <label>Gender</label>
                <div className="newUserGender">
                    {/* <input type="radio" name="gender" id="male" value="male" onChange={ (e) => { setGender(e.target.value) }} />
                    <label htmlFor="male">Male</label>

                    <input type="radio" name="gender" id="female" value="female" onChange={ (e) => { setGender(e.target.value) }} />
                    <label htmlFor="female">Female</label>

                <input type="radio" name="gender" id="diverse" value="diverse" onChange={ (e) => { setGender(e.target.value) }} /> */}
                {/* <label htmlFor="diverse">Diverse</label> */}

                    <FormControl>
                        {/* <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel> */}
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                        >
                            <FormControlLabel value="female" control={<Radio />} label="Female" onChange={ (e) => { setGender(e.target.value) }} />
                            <FormControlLabel value="male" control={<Radio />} label="Male" onChange={ (e) => { setGender(e.target.value) }} />
                            <FormControlLabel value="diverse" control={<Radio />} label="Diverse" onChange={ (e) => { setGender(e.target.value) }} />
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>
            {/* <div className="newUserItem">
                <label>Active</label>
                <select name="active" id="active" className="newUserSelect">
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </select>
            </div> */}
        </form>
        <div className="buttonWrapper">
            <Link to="/users">
                <button className="newUserButton" onClick={handleUserCreation}>Create</button>
            </Link>
        </div>
        {success === true && <span className="message success">User successfully added.</span>}
        {error.length > 3 && <span className="message error">{error}</span>}
    </div>
  )
}
