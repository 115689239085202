import "./imprint.css";

export default function Imprint() {

  return (
      <div className="imprint">

        <br/>
        <br/>
        <h1>Maschinenbau Dünder</h1>

        {/* <p>
            <h3>Information according to § 5 Telemedia Act, Germany</h3>
            <br />
            <h4>Represented by:</h4>
            Abdullah Dünder<br/>
            Brunsbütteler Damm 454<br/>
            13591 Berlin<br/>

            <br/>
            <br/>

            <h4>Contact:</h4>
            Phone: 030 30837362<br/>
            E-Mail: anlasser-lichtmaschinen-online@web.de<br/>

            <br/>
            <br/>

            <h4>Tax-ID:</h4>
            DE252414395

            <br/>
            <br/>
            <br/>
            
            <h4>Information on Online Dispute Resolution:</h4>
            The EU Commission has created an internet platform for the online settlement of disputes (so-called "OS platform").<br/>
            The OS platform serves as a point of contact for the out-of-court settlement of disputes relating to contractual obligations arising from online sales contracts.<br/>
            You can reach the OS platform under the following link: http://ec.euopa.eu/consumers/odr "Note according to § 36 Consumer Dispute Settlement Act (VSBG)
        </p> */}

        <br/>
        <br/>
        <br/>
        {/* <hr/>
        <br/>
        <br/>
        <br/> */}

        <p>
            <h3>Angaben gemäß § 5 TMG</h3>
            <br />
            <h4>Vertreten durch:</h4>
            Abdullah Dünder<br/>
            Brunsbütteler Damm 454<br/>
            13591 Berlin<br/>

            <br/>
            <br/>

            <h4>Kontakt:</h4>
            Telefon: 030 30837362<br/>
            E-Mail: anlasser-lichtmaschinen-online@web.de<br/>

            <br/>
            <br/>

            <h4>Umsatzsteuer-ID:</h4>
            Umsatzsteuer-Identifikationsnummer gemäß §27a<br/>
            Umsatzsteuergesetz: DE252414395

            <br/>
            <br/>
            <br/>
            
            <h4>Informationen zur Online-Streitbeilegung:</h4>
            Die EU-Kommission hat eine Internetplattform zur Online-Beilegung von Streitigkeiten (sog. „OSPlattform“) geschaffen.<br/>
            Die OS-Plattform dient als Anlaufstelle zur außergerichtlichen Beilegung von Streitigkeiten betreffend vertragliche Verpflichtungen, die aus Online-Kaufverträgen erwachsen.<br/>
            Sie können die OS-Plattform unter dem folgenden Link erreichen: http://ec.euopa.eu/consumers/odr „Hinweis gemäß § 36 Verbraucherstreitbeilegungsgesetz (VSBG)
        </p>
    </div>
  )
}
