import axios from "axios";
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { databaseLocation } from "../../../dummyData";
import InvoicePosition from "../InvoicePosition";
import { saveAs } from "file-saver";
//Checkbox
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function EditInvoice() {

    const defaultPositionObject = {
        Pos: 0,
        ArticleNumber: "Anlasser/Lichtmaschine",
        Description: "Anlasser/Lichtmaschine",
        Quantity: 1,
        Price: Number(10.0),
        Currency: "EUR",
        Tax: "19%"
    }

    const [date, setDate] = useState(new Date())
    const [InvoiceNumber, setInvoiceNumber] = useState("")
    const [Employee, setEmployee] = useState("Abdullah")
    const [PaymentMethod, setPaymentMethod] = useState("Bar")
    const [CustomerData, setCustomerData] = useState("Daniel Rappich\nLynarstr. 5")
    const [Note, setNote] = useState("Bei Gewährleistung wird die erworbene Ware ausgetauscht oder repariert. Kein Anspruch auf Reparatur- oder Ausfallkosten. Bei Taxis und Lieferfahrzeugen keine Gewährleistung.")
    const [CashReceived, setCashReceived] = useState(false)
    const [GoodsPassedToCustomer, setGoodsPassedToCustomer] = useState(false)
    const [InvoicePositions, setInvoicePositions] = useState([])
    const [ErrorMessage, setErrorMessage] = useState({message:"", color:""})
    
    const location = useLocation();
    const navigate = useNavigate();
    const invoiceId = location.pathname.split("/")[2];
    
    useEffect( async () => {
        
        //Get Invoice
        await axios.get(`${databaseLocation}/api/invoice/get/${invoiceId}`).then( (response) => {
            
            console.log("Invoice")
            console.log(response.data[0])
            setCustomerData(response.data[0].customerData.replaceAll("<br/>", "\n"));
            setDate(new Date(response.data[0].date));
            setEmployee(response.data[0].employee);
            setNote(response.data[0].note);
            setPaymentMethod(response.data[0].paymentMethod);
            setCashReceived(response.data[0].cashReceived);
            setGoodsPassedToCustomer(response.data[0].goodsPassedToCustomer);
        });
        
        //Get InvoicePositions
        axios.get(`${databaseLocation}/api/invoicePositions/get/${invoiceId}`).then( (response) => {
            
            console.log("InvoicePositions")
            setInvoicePositions(response.data.sort( (a, b) => a.Pos - b.Pos));
            console.log(response.data)
        });
    }, [])

    const setSpecificInvoicePosition = (position) => {

        let newArr = [...InvoicePositions];
        newArr[position.Pos] = position;
        setInvoicePositions(newArr);
    }

    const deleteInvoicePosition = (position) => {

        let newArr = InvoicePositions.filter(key => key.Pos != position.Pos);
        newArr.map( (key, index) => {
            key.Pos = index;
        })
        setInvoicePositions(newArr);
    }

    const addInvoicePosition = () => {

        let newArr = [...InvoicePositions];
        let newObj = Object.assign({}, defaultPositionObject);
        newObj.Pos = InvoicePositions.length;
        newArr.push( newObj );
        setInvoicePositions(newArr);
    }

    const totalPrice = () => {

        let total = 0;
        InvoicePositions.map( (position) => {
            total += Number(position.Price)
        })
        return total.toFixed(2);
    }

    const showErrorMessage = (msg, color) => {
        setErrorMessage({message:msg, color:color})

        if(msg.toLowerCase().includes("not logged in")) {
            console.log("redirect");
            navigate("/login")
            // <Navigate to="/login" replace={true} />
        }
    }

    const createAndDownloadPdf = async () => {

        const Invoice = {};
        Invoice.InvoicePositions = InvoicePositions;
        Invoice.CustomerData = CustomerData.replaceAll(/\r?\n/g, '<br/>');
        Invoice.Total = totalPrice();
        Invoice.PaymentMethod = PaymentMethod;
        Invoice.Employee = Employee;
        Invoice.Note = Note;
        Invoice.Date = date;
        Invoice.CashReceived = CashReceived;
        Invoice.GoodsPassedToCustomer = GoodsPassedToCustomer;
        Invoice.InvoiceId = invoiceId;
        Invoice.InvoiceNumber = "RE-"+invoiceId;

        //Save in database
        showErrorMessage("Updating Invoice...");
        await axios.put(`${databaseLocation}/api/invoice/${invoiceId}`, { Invoice: Invoice }).then( (response) => {
            
            showErrorMessage(response.data + " Wait for PDF to create..");

            //Create PDF
            axios.post(databaseLocation+"/create-pdf", Invoice).then( () => {

                axios.get(databaseLocation+"/fetch-pdf", { responseType: "blob" }).then( (res) => {
                    
                    const pdfBlob = new Blob([res.data], { type: 'application/pdf' })
    
                    saveAs(pdfBlob, Invoice.InvoiceNumber+'.pdf');
                })
            })
        }).catch( (err) => {
            
            try {
                if(err.response.data) {
                    return showErrorMessage(err.response.data, "red");
                }
            }
            catch {
                showErrorMessage("Something went wrong, please contact an Administrator!", "red");
            }
        })
    }

  return (
    <div className="invoice">
        <h2>Editing RE-{invoiceId}</h2>
        <div className="wrapperRow">
            <div className="flexChild">
                <textarea type="text" placeholder="Kundendaten" value={CustomerData} onChange={ (event) => { setCustomerData(event.target.value) }}/>
            </div>
            <div className="flexChild right">
                {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Datum"
                        value={date}
                        // shouldDisableDate={isWeekend}
                        onChange={(newValue) => {
                            console.log("NewDate: " + newValue)
                            setDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider> */}
                <input type="text" placeholder="Rechnungs-Nr." value={"RE-"+invoiceId} disabled />
                <input type="text" placeholder="Datum" value={date.getDate()+"."+(date.getMonth()+1)+"."+date.getFullYear()} disabled />
                <input type="text" placeholder="Mitarbeiter" value={Employee} onChange={ (event) => { setEmployee(event.target.value); } } />
                <input type="text" placeholder="Zahlungsart" value={PaymentMethod} onChange={ (event) => { setPaymentMethod(event.target.value) } }/>
                <FormGroup className="checkboxes">
                    <FormControlLabel checked={CashReceived} control={<Checkbox />} label="Bezahlung bar erhalten" onChange={ (event) => { setCashReceived(event.target.checked) }} />
                    <FormControlLabel checked={GoodsPassedToCustomer} control={<Checkbox />} label="Ware wurde übergeben" onChange={ (event) => { setGoodsPassedToCustomer(event.target.checked) }} />
                </FormGroup>
            </div>
        </div>
        <div className="wrapper">
            <table>
                <tbody>
                    <tr>
                        <th>Pos</th>
                        {/* <th>Artikelnummer</th> */}
                        <th>Beschreibung</th>
                        <th>Menge</th>
                        <th>Preis</th>
                        {/* <th>Einheit</th> */}
                        <th>UST-Satz</th>
                    </tr>
                    {InvoicePositions.length > 0 && InvoicePositions.map( (position) => {
                        
                        console.log(position.description);
                        return (
                            <InvoicePosition key={position.Pos} position={position} setSpecificInvoicePosition={setSpecificInvoicePosition} deleteInvoicePosition={deleteInvoicePosition} />
                        )
                    })}
                </tbody>
            </table>
            <button className="button addInvoicePosition" onClick={addInvoicePosition}>Add Position</button>
        </div>
        <div className="invoiceFooter">
            <div className="note">
                <textarea type="text" placeholder="Mitteilung" value={Note} onChange={ (event) => { setNote(event.target.value) } }/>
            </div>
            <div className="sum">
                <span className="left total">Gesamt: {totalPrice()} €</span>
            </div>
        </div>
        <div className="buttonWrapper">
            {ErrorMessage.message.length > 0 && <span className={"spanError active "+ErrorMessage.color}>{ErrorMessage.message}</span>}
            <button className="button print" onClick={createAndDownloadPdf}>Save & Print</button>
        </div>
    </div>
  )
}
