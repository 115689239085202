import React from 'react'
import "./notLoggedIn.css";

export default function NotLoggedIn() {
  return (
    <div className="notLoggedIn">
        <h3 className="title">You don't have the rights to do that!</h3>
    </div>
  )
}
