import React, { useEffect, useState } from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { FormControl } from '@mui/material';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Axios from "axios";
import { databaseLocation } from "../../../dummyData";

const SearchByVehicle = ({ articleList, setArticleList, filteredList, setFilteredList }) => {

    const [allBrands, setAllBrands] = useState([]);
    const [brand, setBrand] = useState("");
    const [model, setModel] = useState("");
    const [year, setYear] = useState("");
    const [articleIdsWithVehicleLists, setArticleIdsWithVehicleLists] = useState([]);

    useEffect( async () => {

        await Axios.get(`${databaseLocation}/getAllBrands`).then( (response) => {

            const sortedBrands = response.data.sort( (a,b) => a.brand.localeCompare(b.brand));
            setAllBrands(sortedBrands);
        });
    }, [])
    
    useEffect( async () => {

        if(brand.length < 2) { return; }

        let isMounted = true;

        let newArticleIds = [];
        await Axios.get(`${databaseLocation}/articlesWithVehicles/${brand.split("/")[0]}`).then( (response) => {
            if(isMounted) {
                console.log("Articles loaded from database.")
                console.log(response.data);


                var newArticleList = [];
                const promises = response.data.map( (entry) => {

                    // console.log(entry);
                    if(!newArticleIds.includes(entry.articleId)) {
                        
                        // console.log("FIRST: " + entry.articleNumber +" ID: " + entry.articleId)
                        newArticleIds.push(entry.articleId)

                        newArticleList.push({
                            articleId: entry.articleId,
                            brand: entry.brand,
                            power: entry.power,
                            articleType: entry.articleType,
                            storagePlace: entry.storagePlace,
                            articleNumber: entry.articleNumber,
                            quantity: entry.quantity,
                            manufacturer: entry.manufacturer,
                            state: entry.state,
                            oe: entry.oe,
                            ebayPlus: entry.ebayPlus,
                            URL: entry.URL,
                            proofed: entry.proofed,
                            vehicleListAvailable: entry.vehicleListAvailable,
                            webshop: entry.webshop,
                            vehicles: [{
                                brand: entry.Brand,
                                engine: entry.Engine,
                                model: entry.Model,
                                year: entry.Year,
                                platform: entry.Platform,
                                vType: entry.vType,
                                note: entry.Note
                            }]
                        });
                    }
                    else {
                        const foundArticle = newArticleList.find( (x) => {
                            return x.articleId == entry.articleId;
                        });
                        foundArticle.vehicles.push({
                            brand: entry.Brand,
                            engine: entry.Engine,
                            model: entry.Model,
                            year: entry.Year,
                            platform: entry.Platform,
                            vType: entry.vType,
                            note: entry.Note
                        })
                    }
                })
                Promise.all(promises);
                // setArticleList(newArticleList);
                setFilteredList(newArticleList);
            }
            console.log("NewArticleIds");
            console.log(newArticleIds);

            var unique = newArticleIds.filter(onlyUnique);
                  
            console.log("Unique:");
            console.log(unique);
        })
        .catch( (err) => {
            console.log(err)
        });

        return () => { 
            isMounted = false;
        }

    }, [brand]); // eslint-disable-line react-hooks/exhaustive-deps

    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    const doesArticleHasAVehicleList = (article) => {

        let answer = false;
        articleIdsWithVehicleLists.some( (entry) => {
            if(entry.articleId === article.articleId) {
                // console.log("ArticleId " + article.articleId + " has a vehicleList!")
                answer = true;
            }
        });
        return answer;
    }

    const loadVehiclesForAllArticles = () => {
    
        return new Promise( async (resolve, reject) => {
    
            const promises = await articleList.map( async (article) => {
                return fetchVehicleList(article);
            })
    
            const results = await Promise.all(promises);
            console.log("All VehicleLists loaded:");
            resolve(results);
        });
    }

    const getUniqueBrands = () => {

        // const allBrands = [];
        // articleList.map( (article) => {
        //     if(!allBrands.includes(article.brand)) {
        //         allBrands.push(article.brand);
        //     }
        // })
        console.log("AllBrands: ");
        console.log(allBrands);
        return allBrands;
    }
    
    const getUniqueModels = () => {

        const models = [];
        if(brand.length < 2) { return models; }

        filteredList.map( (article) => {
            if(article.vehicles) {
                article.vehicles.map( (vehicle) => {
                    
                    // console.log("getUniqueModels: " + article.articleNumber);
                    console.log("vehicle.brand: \"" + vehicle.brand + "\" | brand: \"" + brand + "\"");
                    if(String(vehicle.brand).toLowerCase().includes(brand.toLowerCase())) {
                        
                        if(!models.includes(vehicle.model)) {
                            models.push(vehicle.model);
                        }
                    }
                })
            }
        })
        console.log("Models Size: " + models.length)
        return models;
    }

    const getUniqueYears = () => {

        const years = [];
        filteredList.map( (article) => {
            if(article.vehicles) {

                article.vehicles.map( (vehicle) => {
                    
                    if(!years.includes(vehicle.year)) {
                        years.push(vehicle.year);
                    }
                })
            }
        })
        return years;
    }

    const handleBrandChange = async (e) => {

        const promises = await articleList.filter( (article) => {

            let containsVehicleWithThisBrand = false;
            if(article.vehicles) {
                
                article.vehicles.map( (vehicle) => {
                    if(e.target.value.includes(vehicle.brand)) {
                        containsVehicleWithThisBrand = true;
                    }
                })
            }
            return containsVehicleWithThisBrand === true || e.target.value.includes(article.brand);
        })
        const results = await Promise.all(promises);

        console.log("All Articles with brand " + e.target.value + " found:");
        console.log(results);

        setBrand(e.target.value);
        setFilteredList(results);
    }

    const handleModelChange = async (e) => {

        const promises = await filteredList.filter( (article) => {

            let containsVehicleWithThisModel = false;
            if(article.vehicles) {

                article.vehicles.map( (vehicle) => {
                    if(vehicle.model === e.target.value) {
                        containsVehicleWithThisModel = true;
                    }
                })
            }
            return containsVehicleWithThisModel === true;
        })

        const results = await Promise.all(promises);

        console.log("All Articles with model " + e.target.value + " found:");
        console.log(results);

        setModel(e.target.value);
        setFilteredList(results);
    }

    const handleYearChange = async (e) => {

        const promises = await filteredList.filter( (article) => {

            let containsVehicleWithThisModel = false; 
            if(article.vehicles) {
                
                article.vehicles.map( (vehicle) => {
                    if(vehicle.year === e.target.value) {
                        containsVehicleWithThisModel = true;
                    }
                })
            }
            return containsVehicleWithThisModel === true;
        })

        const results = await Promise.all(promises);

        console.log("All Articles with year " + e.target.value + " found:");
        console.log(results);

        setYear(e.target.value);
        setFilteredList(results);
    }

    const fetchVehicleList = async (article) => {
        
        console.log("Fetching VehicleList for " + article.articleId + "..");
        await Axios.get(`${databaseLocation}/api/vehicleList/${article.articleId}/withKType`).then( (response) => {
            
            article.vehicles = response.data;
        });
        console.log(article.vehicles);
        // console.log("Fetching VehicleList for " + article.articleNumber + " finished.");
    }

  return (
    <div className="vehicleFilter">
        {/* <span className="title">Search by Vehicle</span> */}
        <span className="title">Suche nach Fahrzeug</span>
        <FormControl fullWidth sx={{marginTop: 2}}>
            <InputLabel id="select-brand-label">Brand</InputLabel>
            <Select
                labelId="select-brand-label"
                id="select-brand"
                defaultValue=""
                // value={brand}
                label="Brand"
                onChange={handleBrandChange}
            >
                {allBrands.length > 0 && allBrands.map( (entry) => {
                    return(
                        <MenuItem key={entry.brand+"_"+Math.floor(Math.random() * 10000)} value={entry.brand}>{entry.brand}</MenuItem>
                    )
                })}
            </Select>
        </FormControl>

        <FormControl fullWidth sx={{marginTop: 3}}>
        <InputLabel id="select-model-label">Model</InputLabel>
        <Select
            labelId="select-model-label"
            id="select-model"
            defaultValue=""
            // value={model}
            label="Model"
            onChange={handleModelChange}
        >
            {filteredList.length > 0 && getUniqueModels().map( (model) => {
                return(
                    <MenuItem key={model+"_"+Math.floor(Math.random() * 1000)} value={model}>{model}</MenuItem>
                    )
                })}
        </Select>
        </FormControl>

        <FormControl fullWidth sx={{marginTop: 3, marginBottom: 2}}>
        <InputLabel id="select-year-label">Year</InputLabel>
        <Select
            labelId="select-year-label"
            id="select-year"
            defaultValue=""
            // value={year}
            label="Year"
            onChange={handleYearChange}
        >
            {filteredList.length > 0 && getUniqueYears().map( (year) => {
                return(
                    <MenuItem key={year+"_"+Math.floor(Math.random() * 1000)} value={year}>{year}</MenuItem>
                    )
                })}
        </Select>
        </FormControl>
    </div>
  )
}

export default SearchByVehicle