import { Link } from 'react-router-dom';
import "./shopProductsListing.css";
import NoPic from "../../nopic.png"
import Button from "../button/Button";

export default function ShopProductsListing({ article }) {

  return (
      <div className="shopProduct">
        <Link to={"/shop/"+article.articleId} style={{ textDecoration: 'none', color:"black"}}>
            <h3 className="shopProductTitle">{article.brand + " " + article.articleType + " " + article.power.replace(" ", "") + " " + article.articleNumber +" "+article.manufacturer}
                <div className="shopProductDesc">
                    {article.URL && article.URL.length > 3 ? <img src={article.URL} alt={article.articleType} className="shopProductImg" /> : <img src={NoPic} alt="No image available" className="shopProductImg" /> }
                    {<span className="shopProductPrice">{article.ebayPlus} €</span>}
                    {article.quantity > 0 ? <Button type="In Stock" text="Verfügbar" /> : <Button type="Out of Stock" text="Ausverkauft" />}
                </div>
                <div className="shopProductDetails">
                    {/* <span>Power: {article.power}</span> */}
                    {article.volt && <span>Volt: {article.volt}</span>}
                    {article.grooves && <span>{article.articleType === "Lichtmaschine" ? "Grooves:" : "Theeth:"} {article.grooves}</span>}
                </div>
            </h3>
        </Link>
      </div>
  )
}
