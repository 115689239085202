import { Publish } from '@material-ui/icons';
import { DataGrid } from '@mui/x-data-grid';
import { darken, lighten } from '@mui/material/styles';
import Box from '@mui/material/Box';
import axios from 'axios';
import eBayApi from 'ebay-api';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { databaseLocation, productData } from '../../dummyData';
import { Search } from '@material-ui/icons';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import "./orders.css";

const getBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

export default function Orders() {

    const location = useLocation();
    const articleId = location.pathname.split("/")[2];
    // console.log("Location: " + location.pathname;

    const [article, setArticle] = useState(false);
    const [filteredList, setFilteredList] = useState([]);

    // useEffect( () => {

    //     axios.get(`${databaseLocation}/ebay/orders`, (response) => {
    //         console.log(response);
    //         setFilteredList(response.data);
    //     });
    // }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // useEffect( async () => {

    //     let isMounted = true;
    //     await axios.get(`${databaseLocation}/ebay/orders/md-teile`).then( (response) => {
    //         if(isMounted) {
    //             console.log("Orders loaded from database.")
    //             setFilteredList(response.data);
    //             console.log(response.data);
    //         }
    //     }).catch( (err) => {
    //         console.log(err);
    //     });

    //     await axios.get(`${databaseLocation}/ebay/fulfillment`).then( (response) => {
    //         if(isMounted) {
    //             console.log(response.data);
    //         }
    //     }).catch( (err) => {
    //         console.log(err);
    //     });
        
    //     return () => { 
    //         isMounted = false;
    //     }

    // }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( async () => {

        let newList = [];
        await axios.get(`${databaseLocation}/ebay/orders/md-teile`).then( async (response) => {
            // console.log("Orders loaded from database.")
            newList = response.data;
            // setFilteredList(response.data);
            console.log(response.data);
            // console.log(response.data[0]);

            await axios.get(`${databaseLocation}/ebay/orders/anlasser-lichtmaschinen-online`).then( (response2) => {

                console.log("Orders loaded from database.")
                newList = newList.concat(response2.data)
                console.log(response2.data);
            }).catch( (err) => {
                console.log(err);
            });
            
        }).catch( (err) => {
            console.log(err);
        });
        console.log(newList)
        setFilteredList(newList);
        
        // await axios.get(`${databaseLocation}/ebay/fulfillment`).then( (response) => {
        //     console.log(response.data);
        // }).catch( (err) => {
        //     console.log(err);
        // });

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const columns = [
        // { field: 'orderId', headerName: 'ID', width: 120 },
        { field: 'buyer', headerName: 'User', width: 110, renderCell: (params) => {
            return (
                <div className="productListItem">
                    <span>{params.row.buyer.username}</span>
                </div>
            ) },
        },
        { field: 'total', headerName: 'Total', width: 100, renderCell: (params) => {
            return (
                <div className="productListItem">
                    <span>{params.row.pricingSummary.total.value} {params.row.pricingSummary.total.currency == "EUR" ? "€" : params.row.pricingSummary.total.currency}</span>
                </div>
            ) },
        },
        { field: 'creationDate', headerName: 'Date', width: 150, renderCell: (params) => {

            const date = new Date(params.row.creationDate);
            return (
                <>
                    <span>{date.toLocaleString("de-DE")}</span>
                </>
            )
        }},
        { field: 'SKU', headerName: 'SKU', width: 200, renderCell: (params) => {

            return (
                <>
                    <span>{params.row.lineItems[0].sku}</span>
                </>
            )
        }},
        { field: 'Title', headerName: 'Title', width: 200, renderCell: (params) => {

            return (
                <>
                    <span>{params.row.lineItems[0].title}</span>
                </>
            )
        }},
        { field: 'orderFulfillmentStatus', headerName: 'Status', width: 120 },
        { field: 'orderPaymentStatus', headerName: 'Payment', width: 100 },
        { field: 'sellerId', headerName: 'Seller', width: 100 },
        { field: 'Actions', headerName: 'Actions', width: 100, renderCell: (params) => {

            return (
                <>
                    <Search className="icon" onClick={ () => { window.open(`https://www.ebay.de/sh/ord/details?orderid=${params.row.orderId}`) }} />
                    {params.row.lineItems[0].hasOwnProperty("sku") && <Link to={"/products/"+params.row.lineItems[0].sku.split("_")[0]}> <WarehouseIcon className="icon"/> </Link>}
                </>
            )
        }},
      ];

  return (
    <div className="orders">
        <Box sx={{ height: 400, width: '100%',
            '& .super-app-theme--Open': {
            bgcolor: (theme) =>
                getBackgroundColor(theme.palette.info.main, theme.palette.mode),
            '&:hover': {
                bgcolor: (theme) =>
                getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
            },
            },
            '& .super-app-theme--FULFILLED': {
            bgcolor: (theme) =>
                getBackgroundColor(theme.palette.success.main, theme.palette.mode),
            '&:hover': {
                bgcolor: (theme) =>
                getHoverBackgroundColor(
                    theme.palette.success.main,
                    theme.palette.mode,
                ),
            },
            },
            '& .super-app-theme--PartiallyFilled': {
            bgcolor: (theme) =>
                getBackgroundColor(theme.palette.warning.main, theme.palette.mode),
            '&:hover': {
                bgcolor: (theme) =>
                getHoverBackgroundColor(
                    theme.palette.warning.main,
                    theme.palette.mode,
                ),
            },
            },
            '& .super-app-theme--Rejected': {
            bgcolor: (theme) =>
                getBackgroundColor(theme.palette.error.main, theme.palette.mode),
            '&:hover': {
                bgcolor: (theme) =>
                getHoverBackgroundColor(theme.palette.error.main, theme.palette.mode),
            },
            },
        }}>
            <DataGrid
                rows={filteredList}
                getRowId={(row) => row.orderId}
                columns={columns}
                pageSize={15}
                rowsPerPageOptions={[15]}
                // checkboxSelection
                disableSelectionOnClick
                getRowClassName={(params) => `super-app-theme--${params.row.orderFulfillmentStatus}`}
                />
        </Box>
    </div>
  )
}
