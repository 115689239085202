import "./contact.css";

export default function Contact() {

    return (
        <div className="contact">

            <div className="section">
                <h2>Über uns</h2>

                <p>
                    Maschinenbau Dünder ist ein mittelständiges Unternehmen mit Sitz in Berlin Spandau.<br/>
                    Wir sind spezialisiert auf Anlasser & Lichtmaschinen für jegliche Fahrzeugtypen.<br/>
                    Wir führen sowohl generalüberholte als auch neue Aggregate.
                </p>
            </div>

            <div className="section">
                {/* <h2>Contact us</h2> */}
                <h2>Kontakt</h2>
                {/* <span>You can reach us from Mo - Fr from 8 am - 5 pm.</span> */}
                <span>Sie erreichen uns von Mo - Fr von 8:00 - 17:00 Uhr.</span>

                <div className="undersection">
                    <span>Tel.: 030 308 373 62</span>
                    <span>E-Mail: anlasser-lichtmaschinen-online@web.de</span>
                </div>
            </div>

            <div className="section">
                {/* <h4>Address</h4> */}
                <h4>Adresse</h4>
                <span>Maschinenbau Dünder</span>
                <span>Brunsbütteler Damm 454</span>
                <span>13591 Berlin</span>
                <span>Germany</span>
            </div>

            <div className="section bottom">
                {/* <h4>Do you need a webshop?</h4> */}
                <h4>Brauchen Sie einen Webshop?</h4>
                <br/>
                <span>Anfragen an: <b>sales@kernlicht.de</b></span>
            </div>


            {/* <div className="section">
                <h2>About us</h2>

                <p>Maschinenbau Dünder is a medium-sized company based in Berlin Spandau.<br/>
                We are specialized in Starters & Alternators of all types of vehicles. We offer new and refurbished ones.</p>
            </div>

            <div className="section">
                <h2>Contact us</h2>
                <span>You can reach us from Mo - Fr from 8 am - 5 pm.</span>

                <div className="undersection">
                    <span>Tel.: 030 308 373 62</span>
                    <span>E-Mail: anlasser-lichtmaschinen-online@web.de</span>
                </div>
            </div>

            <div className="section">
                <h4>Address</h4>
                <span>Maschinenbau Dünder</span>
                <span>Brunsbütteler Damm 454</span>
                <span>13591 Berlin</span>
                <span>Germany</span>
            </div>

            <div className="section bottom">
                <h4>Do you need a webshop?</h4>
                <br/>
                <span>Requests to: <b>sales@kernlicht.de</b></span>
            </div> */}
      </div>
    )
  }
  