import "../register.css"
import { signUp } from "../../../images";

const CustomerCreation = ({ desiredPath, setFirstname, setLastname, setPhone, setCompany, setStreet, setAddressExtra, setPostalcode, setCity, setCountry, registerHandler}) => {

  return (
    <div className="registerWrapper">
        <div className="registerWrapper2">
            <div>
                <div className="header">
                    <h1>Registrierung</h1>
                    <span className="subheader">Diese Informationen brauchen wir um dich als Händler zu verifizieren.</span>
                </div>
                <div className="registerItems">
                    <div className="registerUserItem">
                        <label>Vorname*</label>
                        <input type="text" onChange={ (e) => { setFirstname(e.target.value); }} />
                    </div>
                    <div className="registerUserItem">
                        <label>Nachname*</label>
                        <input type="text" onChange={ (e) => { setLastname(e.target.value); }} />
                    </div>
                    <div className="registerUserItem">
                        <label>Firma*</label>
                        <input type="text" onChange={ (e) => { setCompany(e.target.value); }} />
                    </div>
                    <div className="registerUserItem">
                        <label>Telefon</label>
                        <input type="text" onChange={ (e) => { setPhone(e.target.value); }} />
                    </div>
                    <div className="registerUserItem">
                        <label>Straße + Hausnummer</label>
                        <input type="text" onChange={ (e) => { setStreet(e.target.value); }} />
                    </div>
                    <div className="registerUserItem">
                        <label>Adresszusatz</label>
                        <input type="text" onChange={ (e) => { setAddressExtra(e.target.value); }} />
                    </div>
                    <div className="registerUserItem">
                        <label>PLZ</label>
                        <input type="text" onChange={ (e) => { setPostalcode(e.target.value); }} />
                    </div>
                    <div className="registerUserItem">
                        <label>Stadt</label>
                        <input type="text" onChange={ (e) => { setCity(e.target.value); }} />
                    </div>
                    <div className="registerUserItem">
                        <label>Land</label>
                        <input type="text" onChange={ (e) => { setCountry(e.target.value); }} />
                    </div>
                    <div className="btnWrapper">
                        <button className="button registerButton" onClick={registerHandler}>Registrieren</button>
                    </div>
                </div>
            </div>
            <img className="signUpImg" src={signUp} alt="sign up" />
        </div>
    </div>
  )
}

export default CustomerCreation