import React, { useEffect, useState } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { data, databaseLocation } from "../dummyData";
import "./chart.css";
import Axios from "axios";

export default function ManufacturerChart({ articleList }) {

    const [manufacturerCounter, setManufacturerCounter] = useState([]);

    const barColors = ["#1f77b4", "#ff7f0e", "#2ca02c", "#962c28", "#a02c79"]

    useEffect( async () => {

        //initiliaze
        let counter = [{ }];
        articleList.map( (article) => {
            counter[0][article.manufacturer] = 0;
        })
        
        //count
        const promises = articleList.map( (article) => {
            
            counter[0][article.manufacturer] += article.quantity;
        })
        const results = Promise.all(promises);
        // console.log(counter)
        
        //take only bigger manufacturers
        let counter2 = [{ Others: 0 }];
        Object.keys(counter[0]).map( (manu) => {

            if(counter[0][manu] > 50) {
                counter2[0][manu] = counter[0][manu];
            }
            else {
                counter2[0]["Others"] += counter[0][manu];
            }

        })
        // console.log(counter2);
        setManufacturerCounter(counter2);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
        {manufacturerCounter.length > 0 && <div className="chart">
            <h3 className="chartTitle">Manufacturer Chart</h3>
            <ResponsiveContainer width="100%" height="100%">
            <BarChart data={manufacturerCounter} margin={{ top: 20 }}>
            {Object.keys(manufacturerCounter[0]).map( (manu, index) => {
                return (
                    <Bar key={manu} dataKey={manu} fill={barColors[index % 5]} label={{ position: 'top' }} />
                )
            })}
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip cursor={{fill: 'transparent'}} />
            <Legend />
            </BarChart>
        </ResponsiveContainer>
        </div>}
    </>
  )
}
