import React, { useState } from 'react'
//Date
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
//TextField
import TextField from '@mui/material/TextField';
//Checkbox
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
//Icons
import { DeleteOutline } from '@material-ui/icons';
//
import axios from "axios";
import { saveAs } from "file-saver";
import { ThreeDRotation } from "@material-ui/icons";
import InvoiceList from "../InvoiceList/InvoiceList";
import InvoicePosition from "../InvoicePosition"
import "./createInvoice.css"
import { databaseLocation } from "../../../dummyData";

export default function CreateInvoice() {

    const defaultPositionObject = {
        Pos: 0,
        ArticleNumber: "Anlasser/Lichtmaschine",
        Description: "Anlasser/Lichtmaschine",
        Quantity: 1,
        Price: Number(10.0),
        Currency: "EUR",
        Tax: "19%"
    }

    const today = new Date();
    const [date, setDate] = useState(today.getFullYear()+"-"+(today.getMonth()+1)+"-"+today.getDate())
    const [InvoiceNumber, setInvoiceNumber] = useState("")
    const [Employee, setEmployee] = useState("Abdullah")
    const [PaymentMethod, setPaymentMethod] = useState("Bar")
    const [CustomerData, setCustomerData] = useState("Daniel Rappich\nLynarstr. 5")
    const [Note, setNote] = useState("Bei Gewährleistung wird die erworbene Ware ausgetauscht oder repariert. Kein Anspruch auf Reparatur- oder Ausfallkosten. Bei Taxis und Lieferfahrzeugen keine Gewährleistung.")
    const [CashReceived, setCashReceived] = useState(false)
    const [GoodsPassedToCustomer, setGoodsPassedToCustomer] = useState(true)
    const [InvoicePositions, setInvoicePositions] = useState([ defaultPositionObject ]);
    const [Invoice, setInvoice] = useState({
        InvoicePositions,
        CustomerData,
        Employee,
        PaymentMethod,
        Note,
        InvoiceNumber
    })
    const [ErrorMessage, setErrorMessage] = useState({message:"", color:""})
    
    const setSpecificInvoicePosition = (position) => {

        let newArr = [...InvoicePositions];
        newArr[position.Pos] = position;
        setInvoicePositions(newArr);
    }

    const deleteInvoicePosition = (position) => {

        let newArr = InvoicePositions.filter(key => key.Pos != position.Pos);
        newArr.map( (key, index) => {
            key.Pos = index;
        })
        setInvoicePositions(newArr);
    }

    const addInvoicePosition = () => {

        let newArr = [...InvoicePositions];
        let newObj = Object.assign({}, defaultPositionObject);
        newObj.Pos = InvoicePositions.length;
        newArr.push( newObj );
        setInvoicePositions(newArr);
    }

    const totalPrice = () => {

        let total = 0;
        InvoicePositions.map( (position) => {
            total += Number(position.Price)
        })
        return total.toFixed(2);
    }

    const showErrorMessage = (msg, color) => {
        setErrorMessage({message:msg, color:color})
    }

    const createAndDownloadPdf = () => {

        Invoice.InvoicePositions = InvoicePositions;
        Invoice.CustomerData = CustomerData.replaceAll(/\r?\n/g, '<br/>');
        Invoice.Total = totalPrice();
        Invoice.PaymentMethod = PaymentMethod;
        Invoice.Employee = Employee;
        Invoice.Note = Note;
        Invoice.CashReceived = CashReceived;
        Invoice.GoodsPassedToCustomer = GoodsPassedToCustomer;
        Invoice.Date = date;
        
        //Save in database
        axios.post(databaseLocation+"/api/invoice/create", Invoice).then( (res) => {
            
            showErrorMessage("Successfully saved invoice.");
            Invoice.InvoiceId = res.data.insertId;
            Invoice.InvoiceNumber = "RE-"+res.data.insertId;

            //Create PDF
            axios.post(databaseLocation+"/create-pdf", Invoice).then( () => {

                axios.get(databaseLocation+"/fetch-pdf", { responseType: "blob" }).then( (res) => {
                    
                    const pdfBlob = new Blob([res.data], { type: 'application/pdf' })
    
                    saveAs(pdfBlob, Invoice.InvoiceNumber+'.pdf');
                })
            }).catch( (err) => {
                console.log(err)
                showErrorMessage(err.message);
            })
        }).catch( (err) => {
            
            try {
                if(err.response.data) {
                    return showErrorMessage(err.response.data, "red");
                }
            }
            catch {
                showErrorMessage("Something went wrong, please contact an Administrator!", "red");
            }
        })
    }

  return (
      <div className="invoice">
        <h2>New Invoice</h2>
        <div className="wrapperRow">
            <div className="flexChild">
                <textarea type="text" placeholder="Kundendaten" value={CustomerData} onChange={ (event) => { setCustomerData(event.target.value) }}/>
            </div>
            <div className="flexChild right">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Datum"
                        value={date}
                        onChange={(newValue) => { setDate(newValue) }}
                        renderInput={(params) => <TextField {...params} />}
                        className="datePicker"
                    />
                </LocalizationProvider>
                <input type="text" placeholder="Mitarbeiter" value={Employee} onChange={ (event) => { setEmployee(event.target.value) } }/>
                <input type="text" placeholder="Zahlungsart" value={PaymentMethod} onChange={ (event) => { setPaymentMethod(event.target.value) } }/>
                <FormGroup className="checkboxes">
                    <FormControlLabel checked={CashReceived} control={<Checkbox />} label="Bezahlung bar erhalten" onChange={ (event) => { setCashReceived(event.target.checked) }} />
                    <FormControlLabel checked={GoodsPassedToCustomer} control={<Checkbox />} label="Ware wurde übergeben" onChange={ (event) => { setGoodsPassedToCustomer(event.target.checked) }} />
                </FormGroup>
            </div>
        </div>
        <div className="wrapper">
            <table>
                <tbody>
                    <tr>
                        <th>Pos</th>
                        {/* <th>Artikelnummer</th> */}
                        <th>Beschreibung</th>
                        <th>Menge</th>
                        <th>Preis</th>
                        {/* <th>Einheit</th> */}
                        <th>UST-Satz</th>
                    </tr>
                    {InvoicePositions.map( (position) => {
                        
                        return (
                            <InvoicePosition key={position.Pos} position={position} setSpecificInvoicePosition={setSpecificInvoicePosition} deleteInvoicePosition={deleteInvoicePosition} />
                        )
                    })}
                </tbody>
            </table>
            <button className="button addInvoicePosition" onClick={addInvoicePosition}>Add Position</button>
        </div>
        <div className="invoiceFooter">
            <div className="note">
                <textarea type="text" placeholder="Mitteilung" value={Note} onChange={ (event) => { setNote(event.target.value) } }/>
            </div>
            <div className="sum">
                <span className="left total">Gesamt: {totalPrice()} €</span>
            </div>
        </div>
        <div className="buttonWrapper">
            <button className="button print" onClick={createAndDownloadPdf}>Save & Print</button>
        </div>
        <div>
            {ErrorMessage.message.length > 0 && <span className={"spanError active "+ErrorMessage.color}>{ErrorMessage.message}</span>}
        </div>
    </div>
  )
}
