import { useState } from 'react';
import "./sidebar.css";
import SidebarMenu from "./SidebarMenu";
import MenuIcon from '@mui/icons-material/Menu';

export default function Sidebar({loggedIn}) {

    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => {
        setSidebar(!sidebar);
    }

  return (
    <>
        <MenuIcon className="openSidebarIcon" onClick={showSidebar} />
        <div className={sidebar ? "sidebarWrapper active" : "sidebarWrapper"}>
            <SidebarMenu loggedIn={loggedIn} setSidebar={setSidebar}/>
        </div>
    </>
  )
}
