import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { DeleteOutline, Edit } from '@material-ui/icons';
import { userRows, databaseLocation } from '../../dummyData';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Axios from "axios";
import "./userList.css";
import NoPic from "../../nopic.png"

export default function UserList() {

    const [data, setData] = useState();

    useEffect( () => {

        let isMounted = true;
        Axios.get(`${databaseLocation}/api/user/getAll`).then( (response) => {
            if(isMounted) {
                console.log("Users loaded from database.");
                console.log(response.data)
                setData(response.data);

                response.data.map( (user) => {
                    if(user.image) {

                        var arrayBufferView = new Uint8Array(user.image.data);
                        var blob = new Blob( [ arrayBufferView ], { type: "image/jpeg" } );
                        var urlCreator = window.URL || window.webkitURL;
                        var imageUrl = urlCreator.createObjectURL( blob );
                        console.log("ImageURL: " + imageUrl)
                        user.avatar = imageUrl;
                        console.log(user);
                        // setUser(user);
                    }
                    else {
                        user.avatar = String(NoPic);
                    }
                })
            }
        });
        
        return () => { 
            isMounted = false;
        }

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleDelete = async (userId) => {

        console.log("Deleting " + userId)
        await Axios.post(`${databaseLocation}/api/deleteUser/${userId}`).then( (response) => {
            
            console.log("Successfully deleted user with userId " + userId);
        }).catch( (err) => {
            console.log(err);
        });
        setData(data.filter(user => user.userId !== userId));
    }

    const columns = [
        // { field: 'userId', headerName: 'ID', width: 40 },
        { field: 'user', headerName: 'Username', width: 120 },
        { field: 'avatar', headerName: '', width: 60, renderCell: (params) => {

            return (
                <img src={params.row.avatar} alt="Avatar" className="topAvatar" />
            )
        }},
        { field: 'level', headerName: 'Level', width: 90 },
        { field: 'firstname', headerName: 'Firstname', width: 150 },
        { field: 'lastname', headerName: 'Lastname', width: 150 },
        { field: 'gender', headerName: 'Gender', width: 80, renderCell: (params) => {

            return (
                params.row.gender === "female" && "Female" ||
                params.row.gender === "male" && "Male" ||
                "Unknown"
            )
        }},
        { field: 'status', headerName: 'Status', width: 90, renderCell: (params) => {
            return (
                params.row.status === 1 && "active" ||
                params.row.status === 0 && "deactivated"
            )
        } },
        { field: 'action', headerName: 'Action', width: 150, renderCell: (params) => {
            return (
                <>
                    <div>
                        <Link to={"/users/"+params.row.userId}>
                            <Edit className="userListEdit" onClick={ console.log("UserId: " + params.row.userId) }>Edit</Edit>
                        </Link>
                    </div>
                    <div>
                        <DeleteOutline className="userListDelete" onClick={ () => handleDelete(params.row.userId)} />
                    </div>
                </>
            )
        } },
      ];

  return (
      <div className="userList">
        <Link to="/newUser" className="addButtonLink">
            <button className="userAddButton">Create</button>
        </Link>
        {data && 
        <Box sx={{ height: 400, width: '100%'}}>
            <DataGrid
                rows={data}
                getRowId={(row) => row.userId}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                // checkboxSelection
                disableSelectionOnClick
                sx={{ paddingLeft: "5px"}}
            />
        </Box>
        }
      </div>
  )
}
