import { Link } from "react-router-dom"
import "./loginRegister.css"

const LoginRegister = () => {

  return (
    <div className="loginRegisterSection">
        <div className="loginRegisterWrapper">
            <h1>Sind Sie bereits Kunde?</h1>
            <Link to="/login">
                <button className="button loginButtons"> Einloggen </button>
            </Link>
            <Link to="/register">
                <button className="button loginButtons"> Registrieren </button>
            </Link>
        </div>
    </div>
  )
}

export default LoginRegister