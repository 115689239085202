import {    LineStyle,
            Timeline,
            TrendingUp,
            PersonOutline,
            Storefront,
            AttachMoney,
            MailOutline,
            DynamicFeed,
            ChatBubbleOutline,
            WorkOutline,
            Report,
            BarChart } from '@material-ui/icons';
import StorefrontIcon from '@mui/icons-material/Storefront';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import SellIcon from '@mui/icons-material/Sell';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import "./sidebar.css";

export default function SidebarMenu({ loggedIn, setSidebar }) {

    const [activeElement, setActiveElement] = useState();

    const handleMenuClick = (event) => {

        if(activeElement)
        {
            activeElement.classList.remove("active");

            event.target.classList.add("active");
            setActiveElement(event.target);
        }
        else
        {
            event.target.classList.add("active");
            setActiveElement(event.target);
        }
        setSidebar(false);
    }

  return (
    <div>

        <div className="sidebarMenu">
            <div className="sidebarHeader">
                {/* <h3 className="sidebarTitle">Dashboard</h3> */}
                <h3 className="sidebarTitle">Menü</h3>
                <CloseIcon className="sidebarIcon" sx={{ cursor: "pointer" }} onClick={ () => setSidebar(false) } />
            </div>
            <ul className="sidebarList">
                <Link to="/shop" className='link'>
                    <li className="sidebarListItem" onClick={(event) => handleMenuClick(event)}>
                        <StorefrontIcon className="sidebarIcon"/>
                        Shop
                    </li>
                </Link>
                <Link to="/contact" className="link" onClick={(event) => handleMenuClick(event)}>
                    <li className="sidebarListItem">
                        <ContactMailIcon className="sidebarIcon"/>
                        Kontakt
                    </li>
                </Link>
                <Link to="/interesting" className="link" onClick={(event) => handleMenuClick(event)}>
                    <li className="sidebarListItem">
                        <AutoStoriesIcon className="sidebarIcon"/>
                        Wissenswertes
                    </li>
                </Link>
            </ul>
        </div>
        {loggedIn === true && 
            <div className="sidebarMenu">
                <h3 className="sidebarTitle">Admin Menu</h3>
                <ul className="sidebarList">
                    { loggedIn === true &&
                        <>
                        <Link to="/home" className="link" disabled>
                            <li className="sidebarListItem" onClick={(event) => handleMenuClick(event)}>
                                <LineStyle className="sidebarIcon"/>
                                Home
                            </li>
                        </Link>
                        </>
                    }
                    <Link to="/users" className="link" onClick={(event) => handleMenuClick(event)}>
                        <li className="sidebarListItem">
                            <PersonOutline className="sidebarIcon"/>
                            Users
                        </li>
                    </Link>
                    <Link to="/products" className="link" onClick={(event) => handleMenuClick(event)}>
                        <li className="sidebarListItem">
                            <Storefront className="sidebarIcon"/>
                            Products
                        </li>
                    </Link>
                    <Link to="/invoice" className="link" onClick={(event) => handleMenuClick(event)}>
                        <li className="sidebarListItem">
                            <DocumentScannerIcon className="sidebarIcon"/>
                            Invoice
                        </li>
                    </Link>
                    <Link to="/orders" className="link" onClick={(event) => handleMenuClick(event)}>
                        <li className="sidebarListItem">
                            <SellIcon className="sidebarIcon"/>
                            Orders
                        </li>
                    </Link>
                    {/* <li className="sidebarListItem">
                        <AttachMoney className="sidebarIcon"/>
                        Transactions
                    </li>
                    <li className="sidebarListItem">
                        <BarChart className="sidebarIcon"/>
                        Reports
                    </li> */}
                </ul>
            </div>
        }
        {/* {loggedIn === true && 
            <div className="sidebarMenu">
                <h3 className="sidebarTitle">Notification</h3>
                <ul className="sidebarList">
                <li className="sidebarListItem">
                        <MailOutline className="sidebarIcon"/>
                        Mail
                    </li>
                    <li className="sidebarListItem">
                        <DynamicFeed className="sidebarIcon"/>
                        Feedback
                    </li>
                    <li className="sidebarListItem">
                        <ChatBubbleOutline className="sidebarIcon"/>
                        Messages
                    </li>
                </ul>
            </div>
        } */}
        {/* {loggedIn === true && 
            <div className="sidebarMenu">
                <h3 className="sidebarTitle">Staff</h3>
                <ul className="sidebarList">
                <li className="sidebarListItem">
                        <WorkOutline className="sidebarIcon"/>
                        Manage
                    </li>
                    <li className="sidebarListItem">
                        <Timeline className="sidebarIcon"/>
                        Analytics
                    </li>
                    <li className="sidebarListItem">
                        <Report className="sidebarIcon"/>
                        Reports
                    </li>
                </ul>
            </div>
        } */}
    </div>
  )
}
