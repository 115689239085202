import React from 'react';
import "./footer.css";
import { NotificationsNone, Language, Settings} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { Divider } from '@mui/material';

export default function Footer() {
  return (
    <div className="footerRename">
        <Divider />
        <div className="footerWrapper">
            <div className="item">
                <span className="title">Kontakt</span>
                
                {/* <span className="subtitle">You can reach us from Mo - Fr from 8 am - 17 pm</span>
                <span>Tel.: 030 308 373 62</span>
                <span>anlasser-lichtmaschinen-online@web.de</span> */}
                <span className="subtitle">Sie können uns von Mo - Fr von 8 - 17 Uhr erreichen.</span>
                <span>Tel.: 030 308 373 62</span>
                <span>E-Mail: sales@md-teile.de</span>
            </div>
            <div className="item">
                {/* <span className="title">Legal Information</span> */}
                <span className="title">Rechtliches</span>

                <Link to="/imprint">
                    {/* <span>Imprint</span> */}
                    <span>Impressum</span>
                </Link>
                <a href="./AGB.pdf" download>AGB</a>
                <Link to="/dataprotection">
                    {/* <span>Data Protection</span>  */}
                    <span>Datenschutzerklärung</span> 
                </Link>
            </div>
        </div>
    </div>
  )
}
