import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Axios from 'axios'
import './login.css'
import NoPic from '../../nopic.png'

const Login = ({ loggedIn, setLoggedIn, setUser, desiredPath, databaseLocation }) => {
	const [loginStatus, setLoginStatus] = useState('')
	const [usernameTextfield, setUsernameTextfield] = useState('')
	const [passwordTextfield, setPasswordTextfield] = useState('')

	let navigate = useNavigate()
	Axios.defaults.withCredentials = true

	useEffect(() => {
		window.addEventListener('keydown', handleKeyPress)
		isLoggedIn()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const onLoggin = (user) => {
		if (user.image) {
			var arrayBufferView = new Uint8Array(user.image.data)
			var blob = new Blob([arrayBufferView], { type: 'image/jpeg' })
			var urlCreator = window.URL || window.webkitURL
			var imageUrl = urlCreator.createObjectURL(blob)
			user.avatar = imageUrl
			setUser(user)
		} else {
			user.avatar = String(NoPic)
		}

		if (desiredPath.length > 1 && desiredPath != '/login') {
			navigate(desiredPath)
		} else {
			navigate('/home')
		}

		loggedIn = true
		setLoggedIn(true)
		setUser(user)
	}

	const isLoggedIn = async () => {
		await Axios.get(`${databaseLocation}/login`).then((response) => {
			setLoggedIn(response.data.loggedIn)

			if (response.data.loggedIn === true) {
				onLoggin(response.data.user)
			}
		})
	}

	const login = async () => {
		await Axios.post(`${databaseLocation}/login`, {
			username: usernameTextfield,
			password: passwordTextfield,
		})
			.then((response) => {
				if (response.status == 200) {
					onLoggin(response.data.user)
				} else {
					setLoginStatus(response.data.msg)
				}
			})
			.catch((err) => {
				setLoginStatus(err.response.data.msg)
			})
	}

	const handleKeyPress = (event) => {
		if (event.charCode === 13 || event.key === 'Enter') {
			if (loggedIn === false) {
				login()
			}
		}
	}

	return (
		<div className='loginSection'>
			<div className='loginWrapper'>
				<div className='loginHeader'>
					<h1>Login</h1>
					<h3 className='loginStatus'>{loginStatus}</h3>
				</div>

				<div className='loginInputs'>
					<input
						type='text'
						placeholder='Benutzername / Email'
						onChange={(e) => setUsernameTextfield(e.target.value)}
					/>
					<input
						type='password'
						placeholder='Passwort'
						onChange={(e) => setPasswordTextfield(e.target.value)}
					/>
					<button className='loginButton' onClick={login}>
						Login
					</button>
				</div>

				<div className='newCustomer'>
					<h4>Neukunde?</h4>
					<Link to='/register'>
						<button className='loginButton'>Registrieren</button>
					</Link>
				</div>
			</div>
		</div>
	)
}

export default Login
